import React from "react";
import { ContactPageStyle } from "../styles/ContactPage.style";
import ContactForm from "../components/ContactForm";
import MapGoogle from "../components/MapGoogle";
import { MainContainer } from "../globalStyles";
import ClientCarausal from "../components/ClientCarausal";

const ContactPage = () => {
  return (
    <>
      <MainContainer>
        <ContactPageStyle>
          <div className="full-width-container">
            <div className="row">
              <div className="col-md-6 col-sm-12 right-container">
                <div className="contact-wrap">
                  <div className="page-title">Contact Us</div>
                  <div className="wps-container">
                    <div className="wps-wrap">
                      <div className="wps-title">Office Address :</div>
                      <div className="wps-data">
                        167, 2nd Floor, Unit-2,
                        <br /> Aggarwal Dharamshala Road, Mithapur Extn. <br />
                        Delhi - 110 044 INDIA
                      </div>
                    </div>
                    <div className="wps-wrap">
                      <div className="wps-title">Contact Number :</div>
                      <div className="wps-data">
                        +91 817-816-5852 (Delhi Office)
                        <br />
                        +91 997-139-8952 (Noida Office)
                        <br />
                        +91 954-046-3785 (Gurgaon Office)
                      </div>
                    </div>
                    <div className="wps-wrap">
                      <div className="wps-title">Email ID :</div>
                      <div className="wps-data">jmtravels89@gmail.com</div>
                    </div>
                    <div className="social-media-wrap"></div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 left-container">
                <MapGoogle />
              </div>
            </div>
          </div>
        </ContactPageStyle>
      </MainContainer>
      <ContactForm />
      <ClientCarausal />
    </>
  );
};

export default ContactPage;
