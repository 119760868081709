import React, { Component } from "react";
import { ContactFormStyle } from "../styles/ContactsForm.style";
import { send } from "emailjs-com";
import { SiMinutemailer } from "react-icons/si";

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  message: "",
  nameError: "",
  emailError: "",
  phoneError: "",
  messageError: "",
  successMessage: "",
  animateIcon: false,
  sendButton: false,
};

class ContactForm extends Component {
  state = initialState;

  handleChange = (e) => {
    const isCheckbox = e.target.type === "checkbox";
    this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
    });
  };

  validate = (e) => {
    let nameError = "";
    let emailError = "";
    let phoneError = "";
    let messageError = "";

    if (!this.state.first_name) {
      nameError = "Name cann't be blank.";
    }
    if (!this.state.email) {
      emailError = "Please enter your email address.";
    } else if (!this.state.email.includes("@")) {
      emailError = "Please enter a valid email address.";
    }
    if (!this.state.phone) {
      phoneError = "Please enter your phone number.";
    } else if (this.state.phone.toString().length <= 6) {
      phoneError = "This field is too short.";
    }
    if (!this.state.message) {
      messageError = "Please write your message.";
    }
    if (emailError || nameError || phoneError || messageError) {
      setTimeout(() => {
        this.setState({ emailError, nameError, phoneError, messageError });
      }, 2000);
      return false;
    }
    return true;
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.setState ({
      nameError: "",
      emailError: "",
      phoneError: '',
      messageError: ''
  });

    this.setState({ animateIcon: true });

    this.setState({ sendButton: true });

    setTimeout(() => {
      this.setState({ sendButton: false });
    }, 2000);

    setTimeout(() => {
      this.setState({ animateIcon: false });
    }, 2000);

    const isValid = this.validate();

    const { first_name, last_name, email, phone, message } = this.state;
    if (isValid) {

      setTimeout(() => {
        this.setState(initialState);
      }, 2000);

      setTimeout(() => {
        this.setState({
          successMessage: "Your message has sent successfully.",
        });
      }, 2000);

      // send(
      //   "service_uys9am9",
      //   "template_08dw4ky",
      //   { first_name, last_name, email, phone, message, countryCodeValue },
      //   "Lt6MyG2LLHa_En768"
      // )
      //   .then((response) => {
      //     console.log(
      //       "Message sent successfully",
      //       response.status,
      //       response.text
      //     );
      //   })
      //   .catch((err) => {
      //     console.log("Failed", err);
      //   });
    }
  };

  render() {
    return (
      <ContactFormStyle>
        <div className="demo1">
          <div className="shape"></div>
          <div className="shape2"></div>
          <div className="demo">
            <div className="outer-container">
              <div className="inner-container">
                <div className="title-wrap">
                  <h3 className="title">
                    Get in Touch
                    <SiMinutemailer className="mail-icon" />
                  </h3>
                  <p className="sub-title">
                    Send us your message our team will revert you soon.
                  </p>
                </div>
              </div>
              <div className="form-container">
                <form onSubmit={this.onSubmit}>
                  <div className="form-top-wrap">
                    <div className="name-container">
                      <div className="name-wrap">
                        <div className="input-title">First Name*</div>
                        <div className="form-block">
                          <input
                            name="first_name"
                            placeholder="Eg. Ashok"
                            value={this.state.first_name}
                            onChange={this.handleChange}
                            maxLength="25"
                            onKeyPress={(e) => {
                              if (!/[A-Z, a-z]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                          <div style={{ fontSize: ".8rem", color: "red" }}>
                            {this.state.nameError}
                          </div>
                        </div>
                      </div>
                      <div className="name-wrap">
                        <div className="input-title">Last Name</div>
                        <div className="form-block">
                          <input
                            name="last_name"
                            placeholder="Eg. Mittal"
                            value={this.state.last_name}
                            onChange={this.handleChange}
                            maxLength="25"
                            onKeyPress={(e) => {
                              if (!/[A-Z, a-z]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-block">
                      <div className="input-title">Email Address*</div>
                      <input
                        name="email"
                        placeholder="Eg. ashok.mittal@gmail.com"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                      <div style={{ fontSize: ".8rem", color: "red" }}>
                        {this.state.emailError}
                      </div>
                    </div>
                    <div className="form-block">
                      <div className="input-title">Mobile Number*</div>
                      <input
                        className="tel-number"
                        name="phone"
                        placeholder="Eg. 9876543210"
                        maxLength="15"
                        value={this.state.phone}
                        onChange={this.handleChange}
                        defaultValue={this.state.phone}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <div style={{ fontSize: ".8rem", color: "red" }}>
                        {this.state.phoneError}
                      </div>
                    </div>
                  </div>
                  <div className="form-middle-wrap">
                    <div className="input-title">Type your message</div>
                    <textarea
                      name="message"
                      placeholder="Message*"
                      value={this.state.message}
                      onChange={this.handleChange}
                      maxLength="400"
                    />
                    <div style={{ fontSize: ".8rem", color: "red" }}>
                      {this.state.messageError}
                    </div>
                  </div>
                  <div className="form-bottom-wrap">
                    <div className="submit-button-wrap">
                      <button type="submit" onClick={this.onSubmit}>
                        {this.state.sendButton === false
                          ? "Submit"
                          : "Submitting..."}
                      </button>
                    </div>
                    <div
                      style={{
                        marginTop: "2rem",
                        fontSize: "1rem",
                        color: "#3399FF",
                      }}
                    >
                      {this.state.successMessage}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ContactFormStyle>
    );
  }
}

export default ContactForm;
