import styled from "styled-components";

export const HeroContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;

  .award-wrap {
    position: absolute;
    top: 2rem;
    left: 2rem;

    @media screen and (max-width: 767px) {
      top: 1rem;
      left: 1rem;
    }

    .award-image {
      max-width: 180px;
      height: auto;

      @media screen and (max-width: 1199px) {
        max-width: 140px;
      }

      @media screen and (max-width: 991px) {
        max-width: 100px;
      }

      @media screen and (max-width: 767px) {
        text-align: left;
        margin-bottom: 1.5rem;
      }

      @media screen and (max-width: 480px) {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-bottom: 1rem;
      }
    }
  }

  .hero-footer-wrap {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 111;

    .column-sm {
      text-align: center;
      padding: 1rem 2.5rem 1rem 2.5rem;
      color: white;
      background-image: linear-gradient(
        to right,
        rgba(21, 38, 96, 0.8),
        rgba(18, 90, 143, 0.8)
      );
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 1199px) {
        padding: 1rem;
      }

      @media screen and (max-width: 991px) {
        flex-direction: column-reverse;
      }

      @media screen and (max-width: 767px) {
        padding: .5rem 0; 
      }

      .wps-left {
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 1.7rem;
        margin-bottom: 0;

        @media screen and (max-width: 1199px) {
          font-size: 1.1rem;
        }

        @media screen and (max-width: 991px) {
          font-size: 1rem;
          line-height: 1rem;
        }

        @media screen and (max-width: 767px) {
          font-size: .8rem;
        }

        @media screen and (max-width: 480px) {
          font-size: .7rem;
          line-height: 1rem;
        }
      }

      .wps-right {
        font-size: 1.1rem;
        font-weight: 500;
        line-height: 1.5rem;
        margin-bottom: 0;

        @media screen and (max-width: 991px) {
          line-height: 1.1rem;
          margin-bottom: .4rem;
        }

        @media screen and (max-width: 480px) {
          margin-bottom: 0;
          font-size: .9rem;
        }

        span {
          font-size: 1.5rem;
          color: var(--secondary);
          margin-left: 0.2rem;

          @media screen and (max-width: 480px) {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
`;

export const HeroSliderStyle = styled.div`
  height: 40rem;
  color: white;
  padding: 5rem;
  background-size: cover;
  background-repeat: no-repeat;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: 1199px) {
  }

  @media screen and (max-width: 991px) {
  }

  @media screen and (max-width: 767px) {
    height: 30rem;
  }

  @media screen and (max-width: 480px) {
    padding: 3rem;
  }

  .hero-title-wrap {
    text-align: left;
    margin-top: 7rem;

    @media screen and (max-width: 1199px) {
    }

    @media screen and (max-width: 991px) {
    }

    @media screen and (max-width: 767px) {
      margin-top: 5rem;
    }

    @media screen and (max-width: 480px) {
      margin-top: 7rem;
    }

    .hero-title {
      font-size: 3.2rem;
      font-weight: 500;
      line-height: 3.2rem;
      text-transform: uppercase;

      @media screen and (max-width: 1199px) {
        font-size: 2.5rem;
        line-height: 2.9rem;
      }

      @media screen and (max-width: 991px) {
        font-size: 2rem;
        line-height: 2.4rem;
      }

      @media screen and (max-width: 767px) {
      }

      @media screen and (max-width: 480px) {
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
    }

    .bold-text {
      font-size: 7rem;
      font-weight: 800;
      line-height: 7.5rem;
      text-transform: uppercase;

      @media screen and (max-width: 1199px) {
        font-size: 5rem;
        line-height: 5.5rem;
      }

      @media screen and (max-width: 991px) {
        font-size: 4rem;
        line-height: 4.5rem;
      }

      @media screen and (max-width: 767px) {
      }

      @media screen and (max-width: 480px) {
        font-size: 2.8rem;
        line-height: 3rem;
      }
    }

    .hero-subtitle {
      text-align: center;
      font-size: 2rem;
      font-weight: 500;
      line-height: 2rem;
      text-shadow: 1px 1px rgba(0, 0, 0, 0.4);

      @media screen and (max-width: 1199px) {
        font-size: 1.8rem;
      }

      @media screen and (max-width: 991px) {
        font-size: 1.5rem;
        line-height: 1.5rem;
      }

      @media screen and (max-width: 767px) {
      }

      @media screen and (max-width: 480px) {
        font-size: 1rem;
        line-height: 1rem;
      }
    }
  }
`;
