import styled from "styled-components";

export const FormComponentStyles = styled.div`
    margin-top: 2rem;

    .input-wrap {
        margin-bottom: 1rem;

        .input-field {
            width: 80%;
            height: 2.5rem;
            padding: 0.5rem;
            outline: none;
            background-color: rgba(255,255,255,1);
            border: none;

            ::placeholder {
                font-style: italic;
                color: grey;
                font-weight: 300;
                font-size: .9rem;
            }
        }

        .message {
            height: 8rem;
        }

    }

    .submit-btn-wrap {
        margin-top: 1rem;

        .submit-btn {
            outline: none;
            border: none;
            padding: .4rem 1.5rem;
            background-color: white;
            color: var(--primary);

            :hover {
                background-color: var(--secondary);
                color: black;
            }
        }
    }

`