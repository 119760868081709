import React from "react";
import { MainContainer, SectionTitle, SectionSubtitle } from "../globalStyles";
import { AboutSectionStyle } from "../styles/AboutSection.style";
import heroImg from "../assets/hero.jpg";
import { PrimaryButtons } from "./Buttons";

const AboutSection = () => {
  return (
    <AboutSectionStyle>
      <MainContainer>
        <SectionTitle>No matter where you travel</SectionTitle>
        <SectionSubtitle className="desktop-only">
          We've got a cab for you
        </SectionSubtitle>

        <div className="row">
          <div className="col-md-6 col-sm-12">
            <img src={heroImg} alt="" className="about-image" />
          </div>
          <div className="col-md-6 col-sm-12">
            <p className="about">
              Planning a weekend getaway? Our outstation cab services will help
              you explore the best destinations, visit all the must-see places
              and taste the best local food. Did you just land at an airport or
              railway station closest to your destination? No problem! You can
              use our airport taxi, the transit pick up service to cover the
              last mile. We'll get you to your destination and show you some of
              the best sights along the way. Planning on traveling home for a
              family get-together? Try our newly introduced one-way cab services
              - no matter where you live, get dropped to your hometown by paying
              only one-side fare. Decided to take a personal day and spend the
              whole day exploring your city? Our local taxi packages will help
              you explore the best places to eat and drink at, some of the
              city's majestic monuments, greenest parks and oldest temples.
              You'll never have to worry about an empty travel itinerary again.
              Are you an offbeat traveller? Do you just hit the road and decide
              to take it from there? We offer one-way drops on several routes,
              in case you only want to be dropped to a destination and don't
              want to look back.
            </p>
            <div className="btn-wrap">
              <PrimaryButtons name="Learn More..." to="/about-jm-travels" />
            </div>
          </div>
        </div>
      </MainContainer>
    </AboutSectionStyle>
  );
};

export default AboutSection;
