import styled from "styled-components";

export const SectionHomeStyle = styled.div`
  color: white;

  .outer-wrap {
    padding: 3rem 50px 2rem 50px;
    /* background-color: rgba(18, 90, 143, 0.5); */
    background-color: rgba(0, 0, 0, 0.7);
    height: 540px;

    @media screen and (max-width: 991px) {
      padding: 3rem 35px 2rem 35px;
      height: 800px;
    }

    @media screen and (max-width: 767px) {
      padding: 3rem 15px 2rem 15px;
      height: 850px;
    }

    @media screen and (max-width: 480px) {
      padding: 3rem 15px 2rem 15px;
      height: 770px;
    }
  }

  .row {
    .wsb-container {
      :first-child {
        @media screen and (max-width: 767px) {
          margin-bottom: 1rem;
        }
      }

      h3 {
        font-size: 3rem;
        font-weight: 600;
        line-height: 3.4rem;
        text-transform: uppercase;
        text-shadow: 2px 2px rgba(0, 0, 0, 0.5);

        @media screen and (max-width: 991px) {
          font-size: 2rem;
          line-height: 2.2rem;
        }
      }

      h5 {
        font-size: 1.1rem;
        margin-bottom: 2rem;
        font-style: italic;
        color: #ff2900;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.5);

        @media screen and (max-width: 991px) {
          font-size: 1rem;
          line-height: 1.2rem;
          margin-bottom: 1rem;
        }
      }

      .txt-content {
        @media screen and (max-width: 480px) {
          font-size: .9rem;
        }
      }

      .map {
        object-fit: contain;
        width: 100%;
        height: 100%;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
      }
    }
  }
`;
