import styled from "styled-components";

export const QuoteModalStyles = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1111;

  .modal-container {
    background-color: var(--primary);
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    z-index: 1111111;
    width: 500px;
    height: 600px;

    .modal-inner-container {
        position: relative;
        padding: 2rem;
        text-align: center;

        .modal-title {
            font-size: 1.3rem;
            line-height: 1.7rem;
            font-weight: 300;
            margin-top: 2rem;
        }

        .modal-wrap {
          
          .close-btn-wrap {
            position: absolute;
            top: 1rem;
            right: 1rem;

            .close-btn {
                font-size: 2rem;

                :hover {
                    cursor: pointer;
                    color: var(--secondary);
                }
            }
          }
        }
      }
    }
  }
`;
