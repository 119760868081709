import styled, { createGlobalStyle } from "styled-components";

const globalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'Source Sans Pro',sans-serif;
        -moz-user-select:none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;

        :root {
            --primary: #1B85D3;
            --secondary: #F7C50A;
            --light-color: #FFFFFF;
            --dark-color: #000000;
            --dark-blue: #125A8F;
            --dark-background: #E9EBEF;
        }
    }
`;

export const SectionTitle = styled.h2`
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 2.4rem;
  text-transform: uppercase;
  text-align: center;

  @media screen and (max-width: 991px) {
    font-size: 1.8rem;
    line-height: 2rem;
  }

  @media screen and (max-width: 767px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
`;

export const SectionSubtitle = styled.h5`
  font-size: 1.1rem;
  line-height: 1.1rem;
  margin-bottom: 2rem;
  font-style: italic;
  text-align: center;

  @media screen and (max-width: 991px) {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  @media screen and (max-width: 767px) {
    /* font-size: 1.5rem; */
  }

  @media screen and (max-width: 480px) {
    font-size: 0.9rem;
    line-height: 1rem;
    margin-top: -0.2rem;
  }
`;

export const MainContainer = styled.div`
  margin: 5rem 0;
  padding: 1rem 50px;
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 400;

  @media screen and (max-width: 1199px) {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 991px) {
    font-size: 1rem;
    line-height: 1.5rem;
    padding-right: 30px;
    padding-left: 30px;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.9rem;
    line-height: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-right: 15px;
    padding-left: 15px;
  }

  .block-title {
    font-size: 1.3rem;
    line-height: 2rem;
    font-weight: 600;
    margin-top: 3rem;
    margin-bottom: 0.5rem;

    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
      line-height: 1.5rem;
      margin-top: 2.5rem;
    }

    @media screen and (max-width: 480px) {
      font-size: 1.1rem;
      line-height: 1.5rem;
      margin-top: 1.7rem;
    }
  }

  .main-title {
    color: var(--primary);
    font-size: 1.8rem;
    line-height: 2.1rem;
    margin-top: 4rem;
    font-weight: 500;

    @media screen and (max-width: 992px) {
      font-size: 1.6rem;
    }

    @media screen and (max-width: 767px) {
      font-size: 1.4rem;
      line-height: 1.8rem;
      margin-top: 3rem;
    }

    @media screen and (max-width: 480px) {
      font-size: 1.2rem;
      line-height: 1.5rem;
      margin-top: 1.8rem;
    }
  }

  .light-title {
    font-size: 1.1rem;
    margin-top: 1rem;
    line-height: 1.4rem;

    @media screen and (max-width: 480px) {
      font-size: 1rem;
      line-height: 1.3rem;
      margin-top: 2rem;
    }
  }

  ul {
    padding-left: 2rem;

    @media screen and (max-width: 992px) {
      padding-left: 1.8rem;
    }

    @media screen and (max-width: 767px) {
      padding-left: 1.4rem;
    }

    @media screen and (max-width: 480px) {
      padding-left: 1.2rem;
    }

    li {
      margin-bottom: 0.5rem;

      @media screen and (max-width: 480px) {
        margin-bottom: 0.3rem;
      }

      span {
        font-weight: 600;
      }
    }
  }
`;

export const PageTitle = styled.div`
  padding: 5rem 50px 2rem 50px;
  color: white;
  background-color: var(--primary);
  margin-bottom: -1rem;
  box-shadow: rgba(99, 99, 99, 0.4) 0px 2px 8px 0px;

  @media screen and (max-width: 991px) {
    padding: 3rem 35px 1rem 35px;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: -2rem;
    padding: 2rem 15px 0.8rem 15px;
  }

  @media screen and (max-width: 480px) {
    padding: 1.5rem 15px 0.005rem 15px;
  }

  .headline {
    font-size: 2.9rem;
    line-height: 3.3rem;
    font-weight: 500;

    @media screen and (max-width: 991px) {
      font-size: 2rem;
      line-height: 2.5rem;
    }

    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
    }

    @media screen and (max-width: 480px) {
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }

  .path {
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 300;
    font-style: italic;
    color: var(--secondary);
    margin-top: -0.4rem;

    @media screen and (max-width: 480px) {
      font-size: 0.9rem;
    }
  }
`;

export const SectionTitle2 = styled.h2`
  font-size: 2.2rem;
  line-height: 2.6rem;
  font-weight: 600;
  margin-top: 1rem;

  @media screen and (max-width: 991px) {
    font-size: 1.8rem;
    line-height: 2rem;
  }

  @media screen and (max-width: 767px) {
    text-transform: uppercase;
    text-align: center;
    font-size: 1.5rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
`;

export const SectionSubtitle2 = styled.h5`
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-style: italic;
  margin-top: -0.3rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 991px) {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: 767px) {
    text-align: center;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.9rem;
    line-height: 1rem;
  }
`;

export const BackToTopButtonStyle = styled.button`
  position: fixed;
  bottom: 223px;
  right: 45px;
  border: none;
  border-radius: 50%;
  background-color: var(--secondary);
  padding: 15px;
  color: white;
  z-index: 9999999;

  @media screen and (max-width: 767px) {
    right: 25px;
    bottom: 25px;
  }

  @media screen and (max-width: 480px) {
    right: 15px;
    bottom: 15px;
  }
`;

export const ColumnText = styled.p``;

export default globalStyle;
