import React from "react";
import styled from "styled-components";

const Styles = styled.div`
  height: 100%;
`;

const MapGoogle = () => {
  return (
    // <iframe
    //   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224414.2370147597!2d77.03526498671874!3d28.495430000000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce71cc43d29d7%3A0x802051a49d63bec6!2sJM%20TRAVELS!5e0!3m2!1sen!2sin!4v1724316977360!5m2!1sen!2sin"
    //   width="100%"
    //   height="100%"
    //   style={{ border: 0 }}
    //   allowfullscreen="true"
    //   loading="lazy"
    //   referrerpolicy="no-referrer-when-downgrade"
    // ></iframe>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224438.04701871323!2d77.16607753182393!3d28.484229739755687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce71cc43d29d7%3A0x802051a49d63bec6!2sJM%20TRAVELS!5e0!3m2!1sen!2sin!4v1732266304946!5m2!1sen!2sin"
      width="100%"
      height="100%"
      style={{border:0}}
      allowfullscreen="true"
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};

export default MapGoogle;
