import styled from "styled-components";

export const PrimaryButtonStyle = styled.div`
    margin-top: 1.5rem;

    .btn-link {
        text-decoration: none;
        color: white;
        padding: .7rem 1.5rem;
        background-color: var(--primary);
        border: none;
        border-radius: 5px;

        :hover {
            color: black;
            background-color: var(--secondary);
            cursor: pointer;
        }
    }
`;