import React from "react";
import { MainContainer, SectionTitle, SectionSubtitle } from "../globalStyles";
import { PlansStyle } from "../styles/Plans.style";

const Plans = () => {
  return (
    <MainContainer>
      <SectionTitle>Our All India Taxi Service</SectionTitle>
      <SectionSubtitle>We provide all india taxi service</SectionSubtitle>
      <PlansStyle>
        <div className="inner-container">
          <div className="row">
            <div className="col-lg-4 col-sm-6 col-12 grid-wrap">
              <div className="inner-wrap">
                <div className="top-wrap">01</div>
                <div className="bottom-wrap">
                  <h3 className="title">Airport Service</h3>
                  <p className="details">
                    Delhi NCR Airport Taxi - Discounted Pickup and Drop Fares.
                    Book your Airport cabs in Delhi NCR as soon as you book your
                    flight.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12 grid-wrap active">
              <div className="inner-wrap">
                <div className="top-wrap">02</div>
                <div className="bottom-wrap">
                  <h3 className="title">Corporate booking</h3>
                  <p className="details">
                    Best In Class Service, Curated Inventory, Multiple Deals &
                    Global Expertise! Cost-effective, smarter and Simpler
                    Business Trips.Amenities: 100% Assured GST Invoice.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12 grid-wrap">
              <div className="inner-wrap">
                <div className="top-wrap">03</div>
                <div className="bottom-wrap">
                  <h3 className="title">Railway station service</h3>
                  <p className="details">
                    Find & compare cabs in New Delhi Railway Station with
                    Cabhit's taxi cab network. Quickly search for best prices
                    and book online. Start your journey now!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12 grid-wrap">
              <div className="inner-wrap">
                <div className="top-wrap">04</div>
                <div className="bottom-wrap">
                  <h3 className="title">Monthly cab service</h3>
                  <p className="details">
                    Cab Gurgaon provide you Car hire, rent and lease based on
                    minimum monthly and yearly rental options for business users
                    and public sector in Gurgaon.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12 grid-wrap">
              <div className="inner-wrap">
                <div className="top-wrap">05</div>
                <div className="bottom-wrap">
                  <h3 className="title">Local package</h3>
                  <p className="details">
                    This service is good & best offer for Gurgaon local city
                    usages that it is also called as 8Hrs / 80kms package. Full
                    Day car rental service is available for ...
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12 grid-wrap">
              <div className="inner-wrap">
                <div className="top-wrap">06</div>
                <div className="bottom-wrap">
                  <h3 className="title">Outstation package</h3>
                  <p className="details">
                    When we plan to book outstation cabs online we consider a
                    lot of thing, which outstation taxi service offers the best
                    in class experience at lowest fares, on- ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PlansStyle>
    </MainContainer>
  );
};

export default Plans;
