import styled from "styled-components";

export const GatewayErrorStyles=styled.div`
    display: block;

    .row {
        justify-content: center;

        .top-container {

            .msg-alert {
                color: red;
                background-color: var(--dark-background);
                padding: 1.5rem 1rem .5rem 1rem;
                font-size: .9rem;
                margin-bottom: 2rem;

                @media screen and (max-width: 767px) {
                    line-height: 1.2rem;
                }

                @media screen and (max-width: 480px) {
                    font-size: .8rem;
                    line-height: 1rem;
                }

                .icon {
                    max-width: 200px;
                    height: auto;
                    display: block;
                    margin-bottom: .5rem;

                    @media screen and (max-width: 767px) {
                        max-width: 120px;
                    }
                }
            }
        }

        .bottom-container {

            .top-wrap {
                margin-top: 1rem;

                .title-wrap {
                    font-size: 2rem;
                    line-height: 2.4rem;
                    font-weight: 500;

                    @media screen and (max-width: 767px) {
                        font-size: 1.5rem;
                        line-height: 2rem;
                    }
                }

                .subtitle-wrap {
                    font-style: italic;
                }
            }

            .bottom-wrap {
                text-align: center;
                margin-top: 1rem;

                .header-wrap {
                    font-size: 1.6rem;
                    font-weight: 500;
                    margin-bottom: .7rem;
                    text-transform: uppercase;

                    @media screen and (max-width: 767px) {
                        font-size: 1.2rem;
                        line-height: 1.7rem;
                    }

                    img {
                        max-width: 50px;
                        height: auto;
                        margin-right: .6rem;

                        @media screen and (max-width: 767px) {
                            max-width: 40px;
                    }
                    }
                }

                .code-wrap {

                    .qr-code {
                        object-fit: contain;
                        width: 50%;
                        height: 50%;

                        @media screen and (max-width: 767px) {
                            width: 60%;
                            width: 60%;
                        }

                        @media screen and (max-width: 480px) {
                            width: 70%;
                            width: 70%;
                        }
                    }
                }

                span {
                    font-size: 1.5rem;
                    font-weight: 500;

                    @media screen and (max-width: 767px) {
                        font-size: 1.3rem;
                        }
                }

                .upi-app-wrap {
                    margin: 1rem 0 2rem 0;

                    .upi-app {
                        max-width: 400px;
                        height: auto;

                        @media screen and (max-width: 767px) {
                            max-width: 300px;
                        }
                    }
                }

                .mobile-wrap {
                    margin-top: 2rem;

                    .payment-btn {
                        padding: .5rem 1.5rem;
                        border: none;
                        border-radius: 5px;
                        text-decoration: none;
                        color: white;
                        background-color: var(--primary);

                        :hover {
                            color: black;
                            background-color: var(--secondary);
                        }
                    }

                    .wps-mobile {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
`;