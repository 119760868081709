import React, { useState } from "react";
import { CallbackSectionStyle } from "../styles/Callback.style";
import taxiIcon from "../assets/book-taxi.png";
import callbackIcon from "../assets/call-back.png";
import contactIcon from "../assets/contact.png";
import { FaWhatsapp } from "react-icons/fa";

const CallbackSection = () => {
  const [thisState, setThisState] = useState({
    text: {
      recipient: process.env.MY_MOBILE_NUMBER,
      textmessage: "",
    },
  });

  const sendText = (_) => {
    const { text } = thisState;
    console.log(thisState);
    console.log("Clicked");
    //pass text message GET variables via query string
    fetch(
      `http://127.0.0.1:4000/send-text?recipient=${text.recipient}&textmessage=${text.textmessage}`
    ).catch((err) => console.error(err));
  };

  const { text } = thisState;

  return (
    <CallbackSectionStyle>
      <div className="inner-wrap">
        <div className="row">
          <div className="col-md-4 col-sm-12 ws-container">
            <img src={taxiIcon} alt="" className="subfooter-icons" />
            <h5 className="head-line">Book Taxi</h5>
            <p className="text-cont">
              We offers city taxis, inter-city cabs, and local & out-station
              cabs at hourly packages.
            </p>
            <div className="action-wrap">
              <a href="https://wa.me/918178165852" className="callback-btn">
                <FaWhatsapp className="whatsapp-icon" />
                Send Message
              </a>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 ws-container">
            <img src={callbackIcon} alt="" className="subfooter-icons" />
            <h5 className="head-line">Call Back</h5>
            <p className="text-cont">
              Drop your mobile number, We will call you within 30 seconds.
            </p>
            <div className="action-wrap">
              <div className="input-wrap">
                <input
                  type="text"
                  className="input-field"
                  value={text.textmessage}
                  onChange={(e) =>
                    setThisState({
                      text: { ...text, textmessage: e.target.value },
                    })
                  }
                />
                <button className="submit-btn" onClick={sendText}>
                  Call Back
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 ws-container">
            <img src={contactIcon} alt="" className="subfooter-icons" />
            <h5 className="head-line">Contact Us</h5>
            <div className="action-wrap">
              <table className="helpline-table">
                <tr>
                  <td className="city">Delhi (HO) :</td>
                  <td className="number">&nbsp; 817-816-5852</td>
                </tr>
                <tr>
                  <td className="city">Gurgaon :</td>
                  <td className="number">&nbsp; 997-139-8952</td>
                </tr>
                <tr>
                  <td className="city">Noida :</td>
                  <td className="number">&nbsp; 954-046-3785</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </CallbackSectionStyle>
  );
};

export default CallbackSection;
