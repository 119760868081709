import styled from "styled-components";

export const NavMainContainer = styled.nav`
  padding: 0.6rem 0 0.4rem 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 11;

  @media screen and (max-width: 1199px) {
  }

  @media screen and (max-width: 991px) {
  }

  @media screen and (max-width: 767px) {
    padding: 1rem 0 1rem 0;
  }

  @media screen and (max-width: 480px) {
  }

  .inner-container {
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
    position: relative;

    @media screen and (max-width: 1199px) {
      padding: 0 35px;
    }

    @media screen and (max-width: 991px) {
    }

    @media screen and (max-width: 767px) {
      padding: 0 15px;
    }

    @media screen and (max-width: 480px) {
    }

    .left-container {
      .logo-wrap {
        .logo {
          max-width: 250px;
          height: auto;
          margin-top: 0.5rem;

          @media screen and (max-width: 991px) {
            max-width: 200px;
          }

          @media screen and (max-width: 767px) {
            margin-top: 0;
            max-width: 180px;
          }

          @media screen and (max-width: 480px) {
            /* max-width: 150px; */
          }
        }
      }
    }
    .right-container {
      @media screen and (max-width: 767px) {
        display: none;
        }

      .menu-container {
        display: flex;
        padding-top: 0.7rem;

        @media screen and (max-width: 1199px) {
        }

        @media screen and (max-width: 991px) {
        }

        @media screen and (max-width: 767px) {
          display: none;
        }

        @media screen and (max-width: 480px) {
        }

        .menu-wrap {
          display: flex;
          align-items: center;
          margin-right: 3rem;

          @media screen and (max-width: 991px) {
            margin-right: 1.5rem;
          }

          @media screen and (max-width: 767px) {
          }

          @media screen and (max-width: 480px) {
          }

          .list-item {
            list-style: none;
            margin-right: 3rem;

            &:last-child {
              margin-right: 0;
            }

            @media screen and (max-width: 991px) {
              margin-right: 1.5rem;
            }

            @media screen and (max-width: 767px) {
            }

            @media screen and (max-width: 480px) {
            }

            .nav-link-item {
              color: black;
              text-decoration: none;

              &:hover {
                color: var(--primary);
              }
            }
          }
        }

        .btn-container {
          margin-top: -0.5rem;

          .menu-btn {
            text-decoration: none;
            padding: 0.5rem 1.5rem;
            background-color: var(--primary);
            color: white;
            border-radius: 5px;
            border: none;

            @media screen and (max-width: 1199px) {
            }

            @media screen and (max-width: 991px) {
            }

            @media screen and (max-width: 767px) {
            }

            @media screen and (max-width: 480px) {
            }
          }
        }
      }
    }
  }
`;

export const MobileMenu = styled.div`
    display: none;

  @media screen and (max-width: 767px) {
    display: block;
    position: absolute;
    right: 15px;
  }

  .toggle-btn-wrap {
    float: left;
    margin-left: 20px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
    }

    .hamburger-icon {
      font-size: 2rem;
      /* color: var(--primary); */

      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const MobileMenuWrap = styled.div`
  width: 80%;
  max-width: 25rem;
  height: 100vh;
  z-index: 9999999;
  position: fixed;
  top: 0;
  right: 0;
  background-color: var(--primary);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: white;
  padding: 2rem;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  border-left: 5px solid var(--secondary);

  @media screen and (max-width: 480px) {
    /* width: 90%; */
  }

  .menu-outer-container {
    .mobile-close-button-wrap {
      text-align: right;
      font-size: 2rem;
      color: white !important;

      @media screen and (max-width: 480px) {
        font-size: 1.5rem;
      }

      .mobile-menu-close-icon {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .mobile-link-container {
      list-style: none;
      margin: 0;
      padding-left: 1rem;
      margin-top: 1rem;

      .mobile-only-menu {
        margin-bottom: 2rem;

        @media screen and (max-width: 480px) {
          /* margin-bottom: 2.5rem; */
          text-align: center;
        }

        .mobile-menu-link {
          text-decoration: none !important;
          color: white;

          &:hover {
            color: var(--secondary);
            cursor: pointer;
          }
        }
      }
    }
  }
`;
