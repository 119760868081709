import styled from "styled-components";

export const ClientCarausalStyle = styled.div`
  margin-top: 3rem;

  @media screen and (max-width: 991px) {
    margin-top: 0;
  }

  .inner-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .card {
      max-width: 6rem;
      border: 0;

      @media screen and (max-width: 991px) {
        max-width: 5rem;
      }

      @media screen and (max-width: 767px) {
        max-width: 4rem;
      }

      @media screen and (max-width: 480px) {
        max-width: 3rem;
      }

      .client-logo {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }
`;
