import styled from "styled-components";

export const Navbar2Style = styled.div`
  background-color: var(--primary);
  padding: 0.8rem 50px 0.8rem 50px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1rem;
  border-top: 3px solid var(--secondary);
  z-index: 111;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 1199px) {
    padding: 0.7rem 35px 0.7rem 35px;
  }

  @media screen and (max-width: 767px) {
    font-size: 0.8rem;
    padding: 0.7rem 15px 0.7rem 15px;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.9rem;
    padding: 0.5rem 15px 0.5rem 15px;
  }

  ::after {
    content: "";
    background-color: var(--secondary);
    width: 250px;
    height: 100%;
    position: absolute;
    right: -30px;
    transform: skew(30deg);

    @media screen and (max-width: 991px) {
      right: -60px;
    }

    @media screen and (max-width: 767px) {
      right: -115px;
    }
  }

  .left-container {
    display: flex;

    .contact {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .mail {
      @media screen and (max-width: 480px) {
        display: none;
      }
    }

    .spacer {
      margin: 0 2rem;
      color: var(--secondary);
      transform: rotate(25deg);

      @media screen and (max-width: 1199px) {
        margin: 0 1.5rem;
      }

      @media screen and (max-width: 991px) {
        margin: 0 1rem;
      }

      @media screen and (max-width: 767px) {
        margin: 0 0.5rem;
      }

      @media screen and (max-width: 480px) {
        display: none;
      }
    }

    .second {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
  .right-container {
    z-index: 11;

    .link-item {
      color: black;
      font-size: 1rem;
      font-weight: 500;

      :hover {
        color: var(--primary);
      }

      @media screen and (max-width: 767px) {
        font-size: 0.8rem;
      }

      @media screen and (max-width: 480px) {
        font-size: 0.9rem;
      }
    }
  }

  .icon {
    font-size: 1.2rem;
    margin-right: 0.5rem;

    @media screen and (max-width: 767px) {
      font-size: 1rem !important;
      margin-right: 0.2rem;
    }

    @media screen and (max-width: 480px) {
      font-size: 1.2rem !important;
    }

    :last-child {
      font-size: 1.4rem;
      margin-top: -5px;
    }
  }

  .link-item {
    text-decoration: none;
    color: white;

    :hover {
      color: var(--secondary);
      cursor: pointer;
    }
  }
`;
