import React from 'react';
import { Link } from 'react-router-dom';
import { PrimaryButtonStyle } from '../styles/Buttons.style';

export const PrimaryButtons = ({to, name}) => {
    return (
        <PrimaryButtonStyle>
            <Link 
                to={to}
                className='btn-link'    
            >
                {name}
            </Link>
        </PrimaryButtonStyle>
    );
};
