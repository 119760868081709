import styled from "styled-components";

export const ContactPageStyle = styled.div`

    .full-width-container {

        .row {

            .left-container {
                overflow: hidden;

                @media screen and (max-width: 767px) {
                    height: 250px;
                }
            }

            .right-container {

                .contact-wrap {
                    padding-top: 1rem;
                    margin-left: 1rem;

                    @media screen and (max-width: 767px) {
                        text-align: center;
                        margin-bottom: 2rem;
                    }

                    .page-title {
                        font-size: 3rem;

                        @media screen and (max-width: 767px) {
                            font-size: 2rem;
                        }
                    }

                    .wps-container {
                        margin-top: 2rem;

                        .wps-wrap {
                            margin-bottom: 1rem;

                            .wps-title {
                                font-size: 1.1rem;
                                line-height: 1.5rem;
                                font-weight: 600;
                            }

                            .wps-data {
                                font-size: 1rem;
                                line-height: 1.4rem;
                                font-weight: 400;
                                font-style: italic;
                            }
                        }

                        .social-media-wrap {}
                    }
                }
            }
        }
    }
`;