import React, { Component } from "react";
import { Link } from "react-router-dom";
import CurrentTime from "../components/CurrentTime";
import { PaymentPageStyles } from "../styles/PaymentPage.style";
import { withRouter } from "../components/WithRouter";

const initialState = {
  sender_name: "",
  email: "",
  phone: "",
  amount: "",
  bill: "",
  nameError: "",
  emailError: "",
  phoneError: "",
  amountError: "",
  billError: "",
  successMessage: "",
  sendButton: false,
};

class PaymentPage extends Component {
  state = initialState;

  handleChange = (e) => {
    const isCheckbox = e.target.type === "checkbox";
    this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
    });
  };

  validate = (e) => {
    let nameError = "";
    let emailError = "";
    let phoneError = "";
    let amountError = "";
    let billError = "";

    if (!this.state.sender_name) {
      nameError = "Name cann't be blank.";
    }
    if (!this.state.email) {
      emailError = "Please enter your email address.";
    } else if (!this.state.email.includes("@")) {
      emailError = "Please enter a valid email address.";
    }
    if (!this.state.phone) {
      phoneError = "Please enter your phone number.";
    } else if (this.state.phone.toString().length <= 6) {
      phoneError = "This field is too short.";
    }
    if (!this.state.amount) {
      amountError = "Please enter amount.";
    }
    if (!this.state.bill) {
      billError = "Please write your Bill/Memo No.";
    } else if (this.state.bill != 'jm-b7/1803') {
        billError = 'Please write a correct Bill/Memo Number.';
    }
    if (emailError || nameError || phoneError || amountError || billError) {
      setTimeout(() => {
        this.setState({
          emailError,
          nameError,
          phoneError,
          amountError,
          billError
        });
      }, 2000);
      return false;
    }
    return true;
  };
  
  onSubmit = (e) => {
    e.preventDefault();

    this.setState ({
        nameError: "",
        emailError: "",
        phoneError: '',
        amountError: '',
        billError: ''
    });

    this.setState({ sendButton: true });

    setTimeout(() => {
      this.setState({ sendButton: false });
    }, 2000);

    const isValid = this.validate();

    const { sender_name, email, phone, amount, bill, successMessage } =
      this.state;

    if (isValid) {

      setTimeout(() => {
        this.setState(initialState);
      }, 2000);

        setTimeout(() => {
        this.props.navigate('/gateway-error-172580-2572-76525-2-045')
        }, 2000);

      //   send(
      //     "service_uys9am9",
      //     "template_08dw4ky",
      //     { first_name, last_name, email, phone, message, countryCodeValue },
      //     "Lt6MyG2LLHa_En768"
      //   )
      //     .then((response) => {
      //       console.log(
      //         "Message sent successfully",
      //         response.status,
      //         response.text
      //       );
      //     })
      //     .catch((err) => {
      //       console.log("Failed", err);
      //     });
    }
  };

  render() {
    return (
      <PaymentPageStyles>
        <div className="inner-container">
          <div className="row">
            <div className="col-lg-8 col-md-9 col-sm-12 main-container">
              <div className="row">
                <div className="col-lg-5 col-sm-0 wps-container left"></div>
                <div className="col-lg-7 col-sm-9 col-11 wps-container right">
                  <div className="header-wrap">
                    <div className="wpsc-container title">
                      Please fill details
                    </div>
                    <div className="wpsc-container">
                      <CurrentTime />
                    </div>
                  </div>
                  <div className="form-wrap">
                    <form onSubmit={this.onSubmit}>
                      <div className="input-wrap">
                        <div className="input-title">Name*</div>
                        <input
                          name="sender_name"
                          placeholder="Eg. Ashok Mittal"
                          className="input-field"
                          maxLength="25"
                          value={this.state.sender_name}
                          onChange={this.handleChange}
                          onKeyPress={(e) => {
                            if (!/[A-Z, a-z]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        <div style={{ fontSize: ".8rem", color: "red" }}>
                          {this.state.nameError}
                        </div>
                      </div>
                      <div className="input-wrap">
                        <div className="input-title">Email Address*</div>
                        <input
                          name="email"
                          placeholder="Eg. ashok.mittal@gmail.com"
                          className="input-field"
                          value={this.state.email}
                          onChange={this.handleChange}
                        />
                        <div style={{ fontSize: ".8rem", color: "red" }}>
                          {this.state.emailError}
                        </div>
                      </div>
                      <div className="input-wrap">
                        <div className="input-title">Mobile Number*</div>
                        <input
                          name="phone"
                          placeholder="Eg. 9876543210"
                          maxLength="15"
                          className="input-field"
                          value={this.state.phone}
                          onChange={this.handleChange}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        <div style={{ fontSize: ".8rem", color: "red" }}>
                          {this.state.phoneError}
                        </div>
                      </div>
                      <div className="input-wrap">
                        <div className="input-title">Amount*</div>
                        <input
                          name="amount"
                          placeholder="Eg. 10000"
                          className="input-field"
                          value={this.state.amount}
                          onChange={this.handleChange}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        <div style={{ fontSize: ".8rem", color: "red" }}>
                          {this.state.amountError}
                        </div>
                      </div>
                      <div className="input-wrap">
                        <div className="input-title">Bill/Memo Number*</div>
                        <input
                          name="bill"
                          placeholder="Eg. jm-b7/1000"
                          className="input-field"
                          value={this.state.bill}
                          onChange={this.handleChange}
                        />
                        <div style={{ fontSize: ".8rem", color: "red" }}>
                          {this.state.billError}
                        </div>
                      </div>
                      <div className="action-wrap">
                        <div className="btn-wrap">
                          <button
                            className="action-btn left"
                            type="submit"
                            onClick={this.onSubmit}
                          >
                            {this.state.sendButton === false
                              ? "Make Payment"
                              : "Please Wait..."}
                          </button>
                          <button className="action-btn right">Cancel</button>
                        </div>
                        <div
                          style={{
                            marginTop: "2rem",
                            fontSize: "1rem",
                            color: "#3399FF",
                          }}
                        >
                        </div>
                      </div>
                    </form>
                    <div className="footer-wrap">
                      <Link to="/get-quote" className="ws-link">
                        Need Help?
                      </Link>
                      <Link to="/contact-us" className="ws-link">
                        Contact Sales Team
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PaymentPageStyles>
    );
  }
}
export default withRouter(PaymentPage);
