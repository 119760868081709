import React from 'react';
import { MainContainer } from '../globalStyles';
import { GatewayErrorStyles } from '../styles/GatewayError.style';
import { GoDeviceMobile } from "react-icons/go";
import razorPay from '../assets/razorpay-icon.png';
import qrCode from '../assets/qr-code.webp';
import upiApps from '../assets/upi-apps.svg';
import logoIcon from '../assets/logo-icon.png';

const GatewayError = () => {
    return (
        <GatewayErrorStyles>
            <MainContainer>
                <div className="row">
                    <div className="col-lg-6 colo-md-8 col-sm-12 col-12">
                        <div className="top-container">
                            <div className="msg-alert">
                                <img src={razorPay} alt="razorpay" className='icon'/>
                                *Razorpay is temporarily unavailable on our website. Use another payment method.
                            </div>
                        </div>
                        <div className="bottom-container">
                            <div className="top-wrap">
                                <div className="title-wrap">Scan QR Code</div>
                                <div className="subtitle-wrap">To make payment from any UPI app from your mobile.</div>
                            </div>
                            <hr />
                            <div className="bottom-wrap">
                                <div className="header-wrap">
                                    <img src={logoIcon} alt="jm travels" />
                                    JM Travels
                                </div>
                                <div className="code-wrap">
                                    <img src={qrCode} alt="" className="qr-code" />
                                </div>
                                <div className="upi-app-wrap">
                                    <img src={upiApps} alt="" className="upi-app" />
                                </div>
                                <span>Or</span>
                                <div className="mobile-wrap">
                                    <a 
                                        href="upi://pay?pa=jmtravels89@uboi&pn=JM TRAVELS&cu=INR" id="__UPI_BUTTON__"
                                        className="payment-btn"
                                    >
                                        <GoDeviceMobile /> Pay Now
                                    </a>
                                    <p className="wps-mobile">
                                        Click to pay if you are on mobile.
                                    </p>
                                </div>
                                <div className="upi-image-wrap">
                                    <img src="" alt="" className="upi-image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MainContainer>
        </GatewayErrorStyles>
    );
};

export default GatewayError;