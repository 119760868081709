import React from "react";
import { Navbar2Style } from "../styles/Navbar2.style";
import { FaWhatsapp, FaEnvelope } from "react-icons/fa";
import { MdCall } from "react-icons/md";
import { Link } from "react-router-dom";

const Navbar2 = () => {
  return (
    <Navbar2Style>
      <div className="left-container">
        <a
          className="link-item mail"
          href="mailto:jmtravels89@gmail.com?body=My custom mail body"
        >
          <FaEnvelope className="icon" />
          jmtravels89@gmail.com
        </a>
        <p className="spacer">|</p>
        <a href="tel:+918178165852" className="link-item mobile">
          <MdCall className="icon" />
          (+91) 817-816-5852
        </a>
        <p className="spacer second">|</p>
        <Link to="/contact-us" className="link-item contact">
          Contact Us
        </Link>
      </div>
      <div className="right-container">
        <a className="link-item" href="https://wa.me/918178165852">
          <FaWhatsapp className="icon" />
          Message Us
        </a>
      </div>
    </Navbar2Style>
  );
};

export default Navbar2;
