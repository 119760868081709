import React from "react";
import chardhamBg from "../assets/chardham.png";
import { SectionHomeStyle } from "../styles/ServicesHome.style";
import chardhamMap from "../assets/chardham-map.png";
import { Parallax } from "react-parallax";

const ServicesHome = () => {
  return (
    <SectionHomeStyle>
      <Parallax bgImage={chardhamBg} bgImageAlt="my img" strength={-500}>
        <div className="outer-wrap">
          <div className="row">
            <div className="col-lg-6 col-md-12 wsb-container">
              <h3>Char Dham Yatra Car Rental</h3>
              <h5>We provide clean and luxury taxi for char dham yatra</h5>
              <p className="txt-content">
                Chardham Taxi Service is one the prominent transport service to
                travel across the Chardham of Uttarakhand which includes
                Yamunotri, Gangotri, Kedarnath and Badrinath Dham. A
                large number of pilgrim and devotees visits every year for
                worship Maa Ganga, Maa Yamuna Lord Shiva and Lord Vishnu. In
                ancient time devotees used to cover the entire distance by foot
                which starts from Haridwar but now people visiting chardham have
                to cover within certain time frame and for this transport play
                an important role to travel to entire chardham where they need a
                expert driver with well maintained vehicle. Devbhumi Taxi
                Service is best travel agency for chardham yatra gives you
                plenty of options on cabs and taxi for chardham depending on
                number of people travelling. For 4 members generally people book
                sedan, between 5-7 members SUV is quite comfortable and if more
                than 9 people generally we take them in Tempo Traveler or if
                they are travelling in group of 20 or 20+ ,buses comes into
                play.
              </p>
            </div>
            <div className="col-lg-6 col-md-12 wsb-container">
              <img src={chardhamMap} alt="" className="map" />
            </div>
          </div>
        </div>
      </Parallax>
    </SectionHomeStyle>
  );
};

export default ServicesHome;
