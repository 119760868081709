import styled from "styled-components";

export const ContactFormStyle = styled.div`
  margin: 10rem 0;

  .demo1 {
    position: relative;
    height: 25rem;
    background-color: var(--primary);

    .shape {
      position: absolute;
      right: 35rem;
      height: 0;
      width: 0;
      border-left: 200px solid transparent;
      border-right: 200px solid rgba(21, 38, 96, 0.15);
      border-bottom: 200px solid transparent;
      border-top: 200px solid transparent;

      @media screen and (max-width: 992px) {
        right: 29rem;
      }

      @media screen and (max-width: 767px) {
        right: 27rem;
      }

      @media screen and (max-width: 480px) {
        right: 17rem;
      }
    }

    .shape2 {
      position: absolute;
      right: 30rem;
      height: 0;
      width: 0;
      border-left: 200px solid transparent;
      border-right: 200px solid rgba(21, 38, 96, 0.15);
      border-bottom: 200px solid transparent;
      border-top: 200px solid transparent;

      @media screen and (max-width: 992px) {
        right: 24rem;
      }

      @media screen and (max-width: 767px) {
        right: 22rem;
      }
    }

    .demo {
      position: absolute;
      top: -4.2rem;
      right: 5rem;

      @media screen and (max-width: 480px) {
        right: 2rem;
        }
    }
  }

  .outer-container {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 480px) {
          justify-content: center;
        }

    .inner-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10rem;
      color: white;

      @media screen and (max-width: 991px) {
        margin-right: 2rem;
        justify-content: flex-start;
      }

      @media screen and (max-width: 767px) {
          display: none;
        }

      .title-wrap {
        margin-left: 1rem;
        
        .mail-icon {
          font-size: 4rem;
          margin-left: 1rem;

          @media screen and (max-width: 991px) {
            font-size: 2.5rem;
          }
        }

        h3 {
          font-size: 3rem;
          font-weight: 600;

          @media screen and (max-width: 991px) {
            font-size: 2rem;
          }
        }

        p {
          margin-top: -0.5rem;
          font-style: italic;

          @media screen and (max-width: 991px) {
            font-size: .9rem;
          }
        }
      }
    }

    .form-container {
      background-color: white;
      width: 30rem;
      padding: 2rem 2rem 1rem 2rem;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      z-index: 11;

      @media screen and (max-width: 767px) {
        width: 25rem;
      }

      @media screen and (max-width: 480px) {
          width: 22rem;
        }

      .form-top-wrap {
        .name-container {
          display: flex;
          justify-content: space-between;

          .name-wrap {
            width: 48%;
          }
        }

        .form-block {
          margin-bottom: 0.8rem;

          input {
            height: 2.5rem;
          }

          .tel-number {
          }
        }
      }

      .input-title {
        margin-bottom: 0.2rem;
      }
    }

    .form-middle-wrap {
      textarea {
        height: 8rem;
        border: none !important;
      }
    }

    .form-bottom-wrap {
      text-align: center;
      margin-top: 1rem;

      .submit-button-wrap {
        button {
          color: white;
          background-color: var(--primary);
          border: none;
          outline: none;
          padding: 0.4rem 2.4rem;
          border-radius: 5px;
        }
      }
    }

    input,
    textarea {
      width: 100%;
      /* margin-bottom: 0.8rem; */
      padding: 5px;
      outline: none;
      border: none;
      border-left: 8px solid var(--primary);
      background-color: #eaeaea;

      ::placeholder {
        font-size: 0.9rem;
        color: #7f7f7f;
        font-style: italic;
        font-weight: 300;
      }

      ::before {
        content: "";
        width: 1rem;
        background-color: var(--primary);
        display: block;
      }
    }
  }
`;
