import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import GlobalStyle from "./globalStyles";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Navbar2 from "./components/Navbar2";
import ContactPage from "./pages/ContactPage";
import AboutPage from "./pages/AboutPage";
import ServicesPage from "./pages/ServicesPage";
import PaymentPage from "./pages/PaymentPage";
import ScrollToTop from './components/ScrollToTop';
import BackToTopButton from "./components/BackToTopButton";
import GatewayError from "./pages/GatewayError";
import ReactGA from "react-ga4";

function App() {

  ReactGA.initialize("G-DMWTYJ6X4Z");

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault()
    }
    document.addEventListener("contextmenu", handleContextMenu)
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu)
    }
  }, [])

  return (
    <div className="App">
      <ScrollToTop />
      <GlobalStyle />
      <Navbar2 />
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/contact-us" element={<ContactPage />} />
        <Route exact path="/about-jm-travels" element={<AboutPage />} />
        <Route exact path="/services" element={<ServicesPage />} />
        <Route exact path="/make-a-payment" element={<PaymentPage />} />
        <Route exact path="/gateway-error-172580-2572-76525-2-045" element={<GatewayError />} />
      </Routes>
      {/* <BackToTopButton /> */}
      <Footer />
    </div>
  );
}

export default App;
