import styled from "styled-components";

export const CallbackSectionStyle = styled.div`
  text-align: center;
  background-color: var(--dark-background);
  padding: 3rem 0 2rem 0;
  font-size: 0.9rem;
  line-height: 1.3rem;
  overflow-x: hidden;

  .row {
    .ws-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      @media screen and (max-width: 767px) {
        margin-bottom: 3rem;
      }

      :last-child {
        @media screen and (max-width: 767px) {
          margin-bottom: 0;
        }
      }

      .subfooter-icons {
        max-height: 80px;
        width: auto;

        @media screen and (max-width: 767px) {
          max-height: 60px;
        }

        @media screen and (max-width: 480px) {
          max-height: 50px;
        }
      }
    }

    .head-line {
      font-size: 1.2rem;
      line-height: 1.5rem;
      font-weight: 600;
      margin-top: 1.2rem;

      @media screen and (max-width: 767px) {
          font-size: 1.1rem;
        margin-top: .5rem;
        }

      @media screen and (max-width: 480px) {
        font-size: 1rem;
        margin-top: .3rem;
      }
    }

    .text-cont {
      width: 80%;
      
      @media screen and (max-width: 767px) {
        line-height: 1rem;
        margin-top: -.3rem;
      }
    }

    .action-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;

      .callback-btn {
        color: white;
        background-color: var(--primary);
        outline: none;
        border: none;
        padding: 0.4rem 1.2rem;
        border-radius: 5px;
        text-decoration: none;

        :hover {
          cursor: pointer;
          color: black;
          background-color: var(--secondary);
        }

        .whatsapp-icon {
          font-size: 1.2rem;
          margin-right: 0.3rem;
        }
      }

      .input-wrap {
        background-color: white;
        border: 1px solid var(--primary);
        border-radius: 5px;
        padding: 2px;

        .input-field {
          border: none;
          outline: none;
          padding-left: 0.4rem;
        }

        .submit-btn {
          padding: 0.2rem 0.8rem;
          background-color: var(--secondary);
          border: none;
          border-radius: 5px;

          :hover {
            cursor: pointer;
            color: white;
            background-color: var(--primary);
          }
        }
      }

      .helpline-table {
        text-align: center;

        .city {
          text-align: right;
        }

        .number {
          text-align: left;
        }
      }
    }
  }
`;
