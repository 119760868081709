import React from "react";
import { FooterContainer } from "../styles/Footer.style";
import logoWhite from "../assets/logo_white.png";
import spIcon from "../assets/secure-payment-icon.png";
import spImage from "../assets/secure_payments.webp";
import { FaHeart, FaFacebookF, FaTwitter, FaYoutube } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <FooterContainer>
      <div className="top-container">
        <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
            <div className="vert-container left">
              <div className="logo-wrap">
                <img src={logoWhite} alt="jm travels logo" className="logo" />
              </div>
              <p className="tagline">
                We provide customers reliable and premium
                Intercity and Local car rental services. We offers Chauffeur
                Driven from mid to luxury segment of cars.
              </p>
              <div className="social-media-container">
                <div className="link-wrap">
                  <a
                    className="list-item"
                    href="https://www.facebook.com/jmtravelsindia?mibextid=ZbWKwL"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookF className="social-media-icon" />
                  </a>
                  <a
                    className="list-item"
                    href="https://instagram.com/jmtravels21?igshid=ZDdkNTZiNTM="
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiFillInstagram className="social-media-icon" />
                  </a>
                  <a
                    className="list-item"
                    href="https://twitter.com/JMTravels0527?t=VpV_a1JAks4OkUJXBpv14g&s=09"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter className="social-media-icon" />
                  </a>
                  <a
                    className="list-item"
                    href="https://youtube.com/@jailsingh1989"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaYoutube className="social-media-icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-5 col-sm-6 col-12">
            <div className="vert-container middle">
              <ul className="list-container">
                <li className="list-head">Office</li>
                <li className="list-item">
                  167, SF, Aggarwal Dharamshala Road, Mithapur Extn. Delhi - 110
                  044
                </li>
                <li className="list-item">
                  <a href="tel:+918178165852">+91 8178165852</a>
                </li>
                <li className="list-item">
                  <a href="mailto:jmtravels89@gmail.com?body=My custom mail body">
                    jmtravels89@gmail.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6 col-12">
            <div className="vert-container right">
              <ul className="list-container">
                <li className="list-head">Quick Links</li>
                <li className="list-item">
                  <Link to="/about-jm-travels" className="link-item">
                    About
                  </Link>
                </li>
                <li className="list-item">
                  <Link to="/services" className="link-item">
                    Services
                  </Link>
                </li>
                <li className="list-item">
                  <Link to="/contact-us" className="link-item">
                    Contact Us
                  </Link>
                </li>
                <li className="list-item">
                  <Link to="/make-a-payment" className="link-item">
                    Make Payment
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 col-12">
            <div className="vert-container payment">
              <div className="secure-payment-wrap">
                <img src={spIcon} alt="icon" className="sp-icon" />
              </div>
              <div className="btn-wrap">
                <Link to="/make-a-payment" className="payment-btn">
                  Quick Payment
                </Link>
              </div>
              <div className="secure-payment-image-wrap">
                <img src={spImage} alt="" className="sp-image" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-container">
        <div className="copyright-container">
          <div className="wps-container">
            Copyright 2024 © JM Travels. All rights reserved.
          </div>
          <div className="wps-container">
            Designed & developed by&nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.artographertacts.com/"
            >
              ArtographerTacts
            </a>
            .
          </div>
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
