import React from "react";
import { HeroContainer, HeroSliderStyle } from "../styles/Hero.style";
import hero2 from "../assets/hero.webp";
import awardImg from "../assets/award.webp";
import corporateImg from "../assets/corporate.webp";
import outstationImg from "../assets/outstation.webp";
import Slider from "react-slick";
import { motion } from "framer-motion";

const Hero = () => {
  // const settings = {
  //   autoplay: true,
  //   autoplaySpeed: 10000,
  //   infinite: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   initialSlide: 0,
  //   speed: 500,
  //   adaptiveHeight: true,
  // };

  return (
    <HeroContainer>
      {/* <Slider {...settings}> */}
      <HeroSlider
        img={hero2}
        title="Welcome to"
        boldText="JM Travels"
        subtitle="All India Taxi Service"
      />
      {/* <HeroSlider
          img={outstationImg}
          title="Best Taxi Service for"
          boldText="Outstation Trips"
          subtitle="Best outstation taxi service in Delhi NCR"
        />
        <HeroSlider
          img={corporateImg}
          title="Our Best"
          boldText="Corporate Cab"
          subtitle="No.#1 well-maintained corporate taxis and cabs"
        /> */}
      {/* </Slider> */}
      <motion.div
        className="award-wrap"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 1,
          delay: 0.5,
        }}
      >
        <img src={awardImg} alt="award jm travels" className="award-image" />
      </motion.div>
      <div className="hero-footer-wrap">
        <div className="wps-wrap">
          <div className="row">
            <div className="col-3 column-sm">
              <p className="wps-left">City Service</p>
              <p className="wps-right">
                65<span>+</span>
              </p>
            </div>
            <div className="col-3 column-sm">
              <p className="wps-left">Corporate Client</p>
              <p className="wps-right">
                1600<span>+</span>
              </p>
            </div>
            <div className="col-3 column-sm">
              <p className="wps-left">Happy Customer</p>
              <p className="wps-right">
                20000<span>+</span>
              </p>
            </div>
            <div className="col-3 column-sm">
              <p className="wps-left">Foreign Client</p>
              <p className="wps-right">
                250<span>+</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </HeroContainer>
  );
};

const HeroSlider = ({ img, title, boldText, subtitle }) => {
  return (
    <HeroSliderStyle style={{ backgroundImage: `url("${img}")` }}>
      <div className="hero-title-wrap">
        <motion.h3
          className="hero-title"
          initial={{ opacity: 0, x: -100 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            duration: 0.5,
            delay: 0.5,
          }}
        >
          {title}
        </motion.h3>
        <motion.h1
          className="bold-text"
          initial={{ opacity: 0, x: -100 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            duration: 0.5,
            delay: 0.8,
          }}
        >
          {boldText}
        </motion.h1>
        <motion.h4
          className="hero-subtitle"
          initial={{ opacity: 0, x: -100 }}
          animate={{ x: 0, opacity: [0, 0.1, 0.2, 1] }}
          transition={{
            duration: 0.5,
            delay: 1,
          }}
        >
          {subtitle}
        </motion.h4>
      </div>
    </HeroSliderStyle>
  );
};

export default Hero;
