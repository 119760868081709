import React, { Component } from "react";
import { FormComponentStyles } from "../styles/FormComponent.style";

const initialState = {
    sender_name: "",
    email: "",
    phone: "",
    message: "",
    nameError: "",
    emailError: "",
    phoneError: "",
    messageError: "",
    successMessage: "",
    sendButton: false,
  };

class FormComponent extends Component {

    state = initialState;

  handleChange = (e) => {
    const isCheckbox = e.target.type === "checkbox";
    this.setState({
      [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
    });
  };

  validate = (e) => {
    let nameError = "";
    let emailError = "";
    let phoneError = "";
    let messageError = "";

    if (!this.state.sender_name) {
      nameError = "Name cann't be blank.";
    }
    if (!this.state.email) {
      emailError = "Please enter your email address.";
    } else if (!this.state.email.includes("@")) {
      emailError = "Please enter a valid email address.";
    }
    if (!this.state.phone) {
      phoneError = "Please enter your phone number.";
    } else if (this.state.phone.toString().length <= 6) {
      phoneError = "This field is too short.";
    }
    if (!this.state.message) {
      messageError = "Please type your message.";
    }

    if (emailError || nameError || phoneError || messageError) {
      setTimeout(() => {
        this.setState({
          emailError,
          nameError,
          phoneError,
          messageError,
        });
      }, 2000);
      return false;
    }
    return true;
  };
  
  onSubmit = (e) => {
    e.preventDefault();

    this.setState ({
        nameError: "",
        emailError: "",
        phoneError: '',
        messageError: '',
        successMessage: ''
    });

    this.setState({ sendButton: true });

    setTimeout(() => {
      this.setState({ sendButton: false });
    }, 2000);

    const isValid = this.validate();

    const { sender_name, email, phone, message } =
      this.state;

    if (isValid) {

      setTimeout(() => {
        this.setState(initialState);
      }, 2000);

      setTimeout(() => {
        this.setState({
          successMessage: "Your message has sent successfully.",
        });
      }, 2000);

      //   send(
      //     "service_uys9am9",
      //     "template_08dw4ky",
      //     { first_name, last_name, email, phone, message, countryCodeValue },
      //     "Lt6MyG2LLHa_En768"
      //   )
      //     .then((response) => {
      //       console.log(
      //         "Message sent successfully",
      //         response.status,
      //         response.text
      //       );
      //     })
      //     .catch((err) => {
      //       console.log("Failed", err);
      //     });
    }
  };

  render() {
    return (
      <FormComponentStyles>
        <form onSubmit={this.onSubmit}>
          <div className="input-wrap">
            <input
              name="sender_name"
              className="input-field"
              placeholder="Your Name*"
              maxLength=""
              value={this.state.sender_name}
              onChange={this.handleChange}
              onKeyPress={(e) => {
                if (!/[A-Z, a-z]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            <div style={{ fontSize: ".8rem", color: "red" }}>
              {this.state.nameError}
            </div>
          </div>
          <div className="input-wrap">
            <input
              name="phone"
              className="input-field"
              placeholder="Your Number*"
              maxLength=""
              value={this.state.phone}
              onChange={this.handleChange}
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            <div style={{ fontSize: ".8rem", color: "red" }}>
              {this.state.phoneError}
            </div>
          </div>
          <div className="input-wrap">
            <input
              name="email"
              className="input-field"
              placeholder="Your Email*"
              maxLength=""
              value={this.state.email}
              onChange={this.handleChange}
            />
            <div style={{ fontSize: ".8rem", color: "red" }}>
              {this.state.emailError}
            </div>
          </div>
          <div className="input-wrap">
            <textarea
              name="message"
              className="input-field message"
              placeholder="Type your message*"
              maxLength="400"
              value={this.state.message}
              onChange={this.handleChange}
            />
            <div style={{ fontSize: ".8rem", color: "red" }}>
              {this.state.messageError}
            </div>
          </div>
          <div className="submit-btn-wrap">
            <button onClick={this.onSubmit} className="submit-btn" type="submit">
              {this.state.sendButton === false ? "Submit" : "Submitting..."}
            </button>
          </div>
          <div
            style={{
              marginTop: "2rem",
              fontSize: "1rem",
              color: "white",
            }}
          >
            {this.state.successMessage}
          </div>
        </form>
      </FormComponentStyles>
    );
  }
}

export default FormComponent;
