import styled from "styled-components";

export const AboutPageStyle = styled.div`
    display: block;

    .ws-container {;

        .ws-image {
            object-fit: contain;
            height: 100%;
            width: 100%;
        }
    }
`;