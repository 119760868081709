import React from "react";
import {
  MainContainer,
  PageTitle,
  SectionSubtitle2,
  SectionTitle2,
} from "../globalStyles";
import { AboutPageStyle } from "../styles/AboutPage.style";
import heroImg from "../assets/hero.jpg";
import ServicesSection from "../components/ServicesSection";
import Testimonials from "../components/Testimonials";
import CallbackSection from "../components/CallbackSection";

const AboutPage = () => {
  return (
    <AboutPageStyle>
      <PageTitle>
        <h1 className="headline">About Us</h1>
        <p className="path">Home / About</p>
      </PageTitle>
      <MainContainer>
        <div className="ws-container">
          <SectionTitle2>About JM Travels</SectionTitle2>
          <SectionSubtitle2>All india taxi service</SectionSubtitle2>
          <p className="about-content">
            One of the finest people mobility companies in India with impressive
            separate divisions serving corporate executive car rental and
            employee transportation, serving travel agents, tour operators and
            Event management companies for their ground transportation needs. JM
            Travels (one of the best Delhi based travel agency) offers Chauffeur
            Driven from mid to luxury segment of cars ranging with brands like
            Tata, Volvo, Imported Mercedes and Toyota Coaches with excellent
            conditions with great mileage. Our team comprises experienced and
            trained drivers who have expertise in driving in all kinds of
            terrains, the only company offering Chrysler Stretch Limousines.
            With a professional management and offices in Delhi, Noida, Gurgaon,
            Jaipur, Mumbai, , Kolkata, Bangalore, Lucknow, Agra and Chandigarh.
            JM Travels is now probably India’s largest B2B passenger
            transportation company. JM Travels has always been a leader in tech
            with in house technology driving every aspect of the business to
            offer a seamless experience to our valuable customers. Thanks to all
            our loyal clients and team members who have stood by us over the
            years, the brand has been built on operational excellence and
            repeated customers. JM Travels has been awarded with different types
            of Awards for years. We are India’s Leading Employee Transportation
            Company since 2014.
          </p>
          <div className="block-title">Steps involved in taxi booking</div>
          <p className="content">
            Book a cab with us by following these simple steps -
            <ul>
              <br />
              <li>
                Visit our website (www.jmtravels.co.in) or call us on
                08178165852 to make a booking with us.
              </li>
              <li>
                When booking online on our website, click on WhatsApp Message
                button or enter your number into Request Callback section.
              </li>
              <li>
                You can also click Request Quote button to get specific quote.
              </li>
              <li>
                One of our customer executives will shortly get in touch with
                you.
              </li>
            </ul>
          </p>
          <div className="block-title">
            Why choose Savaari for one way car rental service
          </div>
          <div className="block-title light-title">
            One-way rentals are ideal when -
          </div>
          <p className="content">
            <ul>
              <li>
                You want to avoid air travel, and last-minute airline delays or
                cancellations
              </li>
              <li>You are moving to a new city</li>
              <li>You want to want avoid traveling with strangers</li>
              <li>
                You are traveling with kids and grandparents and hence
                door-to-door pickup is a priority
              </li>
              <li>
                You are taking an unplanned trip and you are not sure when you'd
                return
              </li>
            </ul>
          </p>
          <div className="block-title light-title">Why Choose JM Travels!</div>
          <p className="content">
            <ul>
              <li>
                <span>Accessibility</span> : Be it any Tier I, II or III city,
                Savaari will bring you home safe. You can book a cab anywhere,
                any time, and be rest assured that a JM Travels will be on its way.
              </li>
              <li>
                <span>Safety</span> : One-way cabs provide a dedicated space
                along with door to door connectivity to its commuters. This
                takes care of any safety concerns that may arise due to unwanted
                contact or exposure to high-risk public places such as airports
                or train stations.
              </li>
              <li>
                <span>Affordability</span> : Our prices are inclusive of all
                toll and state taxes. Hence, you need not pay for any additional
                charges during the trip. Leave your wallet home! Savaari's
                one-way fares are the lowest in the industry.
              </li>
              <li>
                <span>Our Promise</span> : JM Travel's promise of safety,
                reliability and convenience during the rides have been
                reinforced by strict adherence to the government issued safety
                guidelines. Besides, we have also launched COVID insurance for
                our driver-partners as well as customers.
              </li>
            </ul>
          </p>
          <div className="block-title">FAQ on one way car rental</div>
          <p className="content">
            <ul>
              <li>
                <span>Who has the cheapest one way car rentals?</span>
                <br />
                Our prices are inclusive of all toll and state taxes. Hence, you
                need not pay for any additional charges during the trip.
                Savaari's one-way fares are the lowest in the industry.
              </li>
              <li>
                <span>Is one way car rental more expensive?</span>
                <br />
                One-way car rentals are cheaper than round-trips. You pay only
                one-side fare for your one-way drop journey. Savaari's one-way
                fares are all-inclusive, implying that the state taxes, toll
                taxes and GST are already included in the fare.
              </li>
              <li>
                <span>How much is one way car rental fee?</span>
                <br />
                Our one-way rentals start as low as INR 949.
              </li>
              <li>
                <span>How to find the best one way car rental deals?</span>
                <br />
                Our one way car rentals are cheaper. The fares may seem higher
                for a few states, but they include all state and toll taxes,
                which means you do not pay anything over and above the price
                displayed against your destination. Round trips on the other
                hand, do not include state and toll taxes. Hence, make use of
                Savaari's one-way launch discounts to avail the lowest fares for
                your upcoming journeys.
              </li>
            </ul>
          </p>
        </div>
      </MainContainer>
      <ServicesSection />
      <Testimonials />
      <CallbackSection />
    </AboutPageStyle>
  );
};

export default AboutPage;
