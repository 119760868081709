import React from "react";
import { ServicesSectionContainer } from "../styles/ServicesSection.style";
import "bootstrap/dist/css/bootstrap.min.css";

const ServicesSection = () => {
  return (
    <ServicesSectionContainer>
      <div className="row section-container">
        <div className="col-lg-4 col-md-6 col-12 column-container">
          <div className="content-wrap">
            <h5 className="column-title">Clean and Swift cab</h5>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12 column-container">
          <div className="content-wrap active">
            <h5 className="column-title">24x7 Customer Support</h5>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12 column-container">
          <div className="content-wrap">
            <h5 className="column-title">Transparent Billing</h5>
          </div>
        </div>
      </div>
    </ServicesSectionContainer>
  );
};

export default ServicesSection;
