import styled from "styled-components";

export const LuxuryCarsStyle = styled.div`
  .left-container {
    margin-top: 1rem;
  }

  .right-container {
    .wps-image {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
`;
