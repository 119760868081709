import styled from "styled-components";

export const ServicesSectionContainer = styled.div`
  padding: 0 50px;

  @media screen and (max-width: 991px) {
    padding: 0 35px;
  }

  @media screen and (max-width: 767px) {
    padding: 0 15px;
  }

  .section-container {
    .column-container {
      padding: 0 1rem;

      @media screen and (max-width: 767px) {
        margin-bottom: 1.5rem;
      }

      @media screen and (max-width: 480px) {
        margin-bottom: 1rem;
      }

      :last-child {
        @media screen and (max-width: 767px) {
          margin-bottom: 0;
        }

        @media screen and (max-width: 480px) {
          margin-bottom: 0;
        }
      }

      .content-wrap {
        position: relative;
        padding: 3rem 2rem 3rem 6rem;
        background-color: #e9ebef;

        @media screen and (max-width: 767px) {
          padding: 2rem 2rem 2rem 5rem;
        }

        @media screen and (max-width: 480px) {
          padding: 2rem 2rem 2rem 4rem;
        }

        ::before {
          content: "";
          position: absolute;
          left: 0;
          top: 1.5rem;
          width: 5rem;
          height: 5rem;
          background-color: var(--secondary);

          @media screen and (max-width: 767px) {
            width: 3rem;
            height: 3rem;
            top: 1rem;
          }

          @media screen and (max-width: 480px) {
            width: 3rem;
            height: 3rem;
          }
        }

        .column-title {
          font-size: 1.3rem;
          font-weight: 600;
          text-transform: uppercase;

          @media screen and (max-width: 767px) {
            font-size: 1.1rem;
          }

          @media screen and (max-width: 480px) {
            font-size: 0.9rem;
          }
        }
      }

      .active {
        background-color: var(--primary);

        .column-title {
          color: white;
        }
      }
    }
  }
`;
