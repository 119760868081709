import  React, { useState , useEffect } from 'react'

const CurrentTime = () => {

    var [date,setDate] = useState(new Date());
    
    useEffect(() => {
        var timer = setInterval(()=>setDate(new Date()), 1000 )
        return function cleanup() {
            clearInterval(timer)
        }
    
    });
    
    return (
        <div>
            {date.toLocaleDateString()} &nbsp; {date.toLocaleTimeString()}
        </div>
    );
};

export default CurrentTime;