import React, { useRef, useEffect, useCallback, useState } from "react";
import { useSpring, animated } from "react-spring";
import { QuoteModalStyles } from "../styles/QuoteModal.style";
import { MdClose } from "react-icons/md";
import FormComponent from "./FormComponent";

const QuoteModal = ({ showModal, setShowModal }) => {

  const modalRef = useRef();

  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? "translateY(0%)" : "translateY(-100%)",
  });

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  return (
    <>
      {showModal ? (
        <QuoteModalStyles ref={modalRef} onClick={closeModal}>
          <animated.div style={animation}>
            <div showModal={showModal} className="modal-container">
              <div className="modal-inner-container">
                <h1 className="modal-title">Request a Quote</h1>
                <hr />
                <div className="modal-wrap">
                  <div
                    onClick={() => setShowModal((prev) => !prev)}
                    className="close-btn-wrap"
                  >
                    <MdClose className="close-btn" />
                  </div>
                  <FormComponent />
                </div>
              </div>
            </div>
          </animated.div>
        </QuoteModalStyles>
      ) : null}
    </>
  );
};

export default QuoteModal;
