import React from "react";
import { SectionTitle, SectionSubtitle } from "../globalStyles";
import { CardStyle, TestimonialsStyle } from "../styles/Testimonials.style";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { MainContainer } from "../globalStyles";
import ramPic from "../assets/ramayan.png";
import vijayPic from "../assets/vijay.png";
import deepakPic from "../assets/deepak.png";
import SliderWrapper from "./_SlickSliderStyle";

const PreBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FaChevronLeft
        className="prevIcon"
        style={{ fontSize: "1.2rem", color: "#1B85D3" }}
      />
    </div>
  );
};

const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FaChevronRight
        className="nextIcon"
        style={{ fontSize: "1.2rem", color: "#1B85D3" }}
      />
    </div>
  );
};

const Testimonials = () => {
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    speed: 500,
    arrows: true,
    adaptiveHeight: true,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>
    ),
  };
  return (
    <MainContainer>
      <TestimonialsStyle>
        <SectionTitle>Happy Passangers</SectionTitle>
        <SectionSubtitle>See what our clients say about us</SectionSubtitle>
        <div className="slider-wrap">
          <div className="slider-inner-wrap">
            <SliderWrapper>
              <Slider
                {...settings}
                prevArrow={<PreBtn />}
                nextArrow={<NextBtn />}
              >
                <Card
                  img={deepakPic}
                  message="Excellent service from booking through to pick up and drop off to the airport. Internet booking was easy, questions we're answered by email promptly. Staff at airport and also pick up from apartment were friendly and helpful. From start to finish excellent service."
                  name="Dr. Deepak Sharma"
                  position="NRI, USA Client"
                />
                <Card
                  img={vijayPic}
                  message="Everything went perfectly! Incredibly punctual, friendly drivers, and a very fast customer service that answered my questions within minutes the night before my return trip. I highly recommend booking here, and will definitely do so again in the future."
                  name="Vijay Kush"
                  position="D.G. Manager, KTC India"
                />
                <Card
                  img={ramPic}
                  message="I salute this agency for their prompt services and follow up. I have no words to express their commitment towards customer. Last but not least that the cabs provided by them are neat and clean with driver who are very polite and of impressive nature."
                  name="Ramayan Gupta"
                  position="Sales Manager, Eco Car"
                />
              </Slider>
            </SliderWrapper>
          </div>
        </div>
      </TestimonialsStyle>
    </MainContainer>
  );
};

const Card = ({ img, message, name, position }) => {
  return (
    <CardStyle>
      <div className="card-wrap">
        <div className="image-wrap">
          <img src={img} alt="" className="card-image" />
        </div>
        <div className="test">
          <p className="message">{message}</p>
        </div>
        <div className="name-wrap">
          <p className="name">
            {name},<span>{position}</span>
          </p>
        </div>
      </div>
    </CardStyle>
  );
};

export default Testimonials;
