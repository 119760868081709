import React, { useState } from "react";
import { MobileMenu, NavMainContainer, MobileMenuWrap } from "../styles/Navbar.style";
import logoImg from "../assets/logo.png";
import { Link } from "react-router-dom";
import QuoteModal from "./QuoteModal";
import { GiHamburgerMenu } from "react-icons/gi";
import { motion, AnimatePresence } from 'framer-motion';

const Navbar = () => {
  const [showModal, setShowModal] = useState(false);

  const [mobileMenu, setMobileMenu] = useState(false);

  const openModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <NavMainContainer>
      <div className="inner-container">
        <div className="left-container">
          <Link to="/" className="logo-wrap">
            <img src={logoImg} className="logo" alt="jm travels logo" />
          </Link>
        </div>
        <div className="right-container">
          <div className="menu-container">
            <ul className="menu-wrap">
              <li className="list-item">
                <Link to="/" className="nav-link-item">
                  Home
                </Link>
              </li>
              <li className="list-item">
                <Link to="/about-jm-travels" className="nav-link-item">
                  About
                </Link>
              </li>
              <li className="list-item">
                <Link to="/services" className="nav-link-item">
                  Services
                </Link>
              </li>
              <li className="list-item">
                <Link to="/contact-us" className="nav-link-item">
                  Contact
                </Link>
              </li>
            </ul>
            <div className="btn-container">
              <button onClick={openModal} className="menu-btn">
                Get Quote
              </button>
              <QuoteModal showModal={showModal} setShowModal={setShowModal} />
            </div>
          </div>
        </div>
        <MobileMenu>
          <div className="toggle-btn-wrap">
            <GiHamburgerMenu 
              className='hamburger-icon'
              onClick={() => {
                setMobileMenu(true);
              }}
            />
          </div>
          <AnimatePresence>
            {mobileMenu && (
              <MobileMenuWrap
                initial={{
                  x: 1000,
                }}
                animate={{
                  x: 0,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 1000,
                  transition: {
                    duration: 0.4,
                  },
                }}
              >
                <div
                  className="menu-outer-container"
                  onClick={() => setMobileMenu(false)}
                >
                  <div className="mobile-close-button-wrap">
                    <span
                      className="mobile-menu-close-icon"
                      onClick={() => setMobileMenu(false)}
                    >
                      <>&#10005;</>
                    </span>
                  </div>
                  <ul className="mobile-link-container">
                    <li className="mobile-only-menu">
                      <Link className="mobile-menu-link" to="/">
                        Home
                      </Link>
                    </li>
                    <li className="mobile-only-menu">
                      <Link
                        className="mobile-menu-link"
                        to="/about-jm-travels"
                      >
                        About Us
                      </Link>
                    </li>
                    <li className="mobile-only-menu">
                      <Link className="mobile-menu-link" to="/services">
                        Services
                      </Link>
                    </li>
                    <li className="mobile-only-menu">
                      <Link className="mobile-menu-link" to="/make-a-payment">
                        Quick Payment
                      </Link>
                    </li>
                    <li className="mobile-only-menu">
                      <Link className="mobile-menu-link" to="/request-quote">
                        Request A Quote
                      </Link>
                    </li>
                    <li className="mobile-only-menu">
                      <Link className="mobile-menu-link" to="/contact-us">
                        Contact Page
                      </Link>
                    </li>
                    <li className="mobile-only-menu">
                      <a className="mobile-menu-link" href="tel:+918178165852">
                        Call Us
                      </a>
                    </li>
                    <li className="mobile-only-menu">
                      <a className="mobile-menu-link" href="mailto:jmtravels89@gmail.com?body=My custom mail body">
                        Mail Us
                      </a>
                    </li>
                    <li className="mobile-only-menu">
                      <a className="mobile-menu-link" href="https://wa.me/918178165852">
                        Message Us - WhatsApp
                      </a>
                    </li>
                  </ul>
                </div>
              </MobileMenuWrap>
            )}
          </AnimatePresence>
        </MobileMenu>
      </div>
    </NavMainContainer>
  );
};

export default Navbar;
