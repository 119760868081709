import styled from "styled-components";

export const FooterContainer = styled.div`
  font-weight: 300;
  padding: 0 50px;
  background-color: var(--primary);
  color: var(--light-color);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 300;

  @media screen and (max-width: 991px) {
    padding: 0 35px;
  }

  @media screen and (max-width: 767px) {
    padding: 0 15px;
  }

  .top-container {
    padding: 2rem 0 1rem 0;
    border-bottom: 1px solid var(--light-color);

    .row {

      ul {
        margin: 0;
        padding: 0;

        li {
          list-style: none;

          a,
          .link-item {
            text-decoration: none;
            color: white;

            :hover {
              color: var(--secondary);
            }
          }
        }
      }
    }

    .vert-container {
      margin-right: 1rem;
      margin-top: 1.5rem;

      @media screen and (max-width: 991px) {
        text-align: center;
      }

      :last-child {
        margin-right: 0;
      }

      .list-container {
        .list-head {
          font-weight: 500;
          text-transform: uppercase;
          margin-bottom: 0.5rem;
        }

        .list-item {
          margin-bottom: 0.6rem;
          line-height: 1.2rem;
        }
      }
    }

    .left {
      margin-top: 0;

      @media screen and (max-width: 991px) {
        margin-bottom: 2rem;
      }

      @media screen and (max-width: 480px) {
        margin-bottom: 0;
      }

      .logo-wrap {
        margin-bottom: 0.5rem;

        .logo {
          max-width: 250px;
          height: auto;

          @media screen and (max-width: 991px) {
            max-width: 200px;
          }
        }
      }

      .tagline {
      }

      .social-media-container {
        margin-top: 0.8rem;

        @media screen and (max-width: 991px) {
          display: flex;
          justify-content: center;
        }
      }

      .link-wrap {
        display: flex;

        .list-item {
          margin-right: 1.6rem;
          color: white;

          :last-child {
            margin: 0;
          }

          .social-media-icon {
            font-size: 1.4rem;

            :hover {
              color: var(--secondary);
              cursor: pointer;
            }
          }
        }
      }
    }

    .payment {
      text-align: center;
      margin-top: 0.5rem;

      .secure-payment-wrap {

        @media screen and (max-width: 767px) {
          display: none;
        }

        .sp-icon {
          max-width: 55px;
          height: auto;
        }
      }

      .secure-payment-image-wrap {
        margin-top: 1rem;

        .sp-image {
          max-width: 200px;
          height: auto;

          @media screen and (max-width: 991px) {
            max-width: 180px;
          }

          @media screen and (max-width: 767px) {
            max-width: 150px;
          }

          @media screen and (max-width: 480px) {
            max-width: 120px;
          }
        }
      }

      .btn-wrap {
        margin-top: 1rem;

        .payment-btn {
          font-size: 1rem;
          line-height: 1.4rem;
          font-weight: 400;
          padding: 0.5rem 1rem 0.4rem 1rem;
          border: none;
          border-radius: 50px;
          color: var(--primary);
          background-color: var(--light-color);
          cursor: pointer;
          text-decoration: none;

          @media screen and (max-width: 767px) {
            background-color: var(--secondary);
            color: black;
          }

          :hover {
            background-color: var(--secondary);
            color: black;
            cursor: pointer;

            @media screen and (max-width: 767px) {
              background-color: var(--secondary);
            }
          }
        }
      }
    }
  }

  .bottom-container {
    padding: 0.5rem 0;
    font-size: 0.9rem;
    font-weight: 300;

    .copyright-container {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 991px) {
        display: block;
        text-align: center;
      }

      .wps-container {
        :first-child {
          @media screen and (max-width: 991px) {
            margin-bottom: 0.2rem;
          }
        }

        .heart-icon {
          color: #d80826;
        }

        a {
          text-decoration: none;
          color: white;
          font-style: italic;
          font-weight: 400;
          font-size: 1rem;

          :hover {
            color: var(--secondary);
          }
        }
      }
    }
  }
`;
