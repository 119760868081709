import styled from "styled-components";

export const PlansStyle = styled.div`
  display: block;

  .inner-container {
    .row {
      .grid-wrap {
        padding: 1rem;

        .inner-wrap {
          height: 20rem;
          padding-top: 3rem;
          background-color: #e9ebef;
          overflow: hidden;

          @media screen and (max-width: 767px) {
            height: 15rem;
          }

          @media screen and (max-width: 480px) {
            height: 12rem;
          }

          :hover {
            background-color: var(--primary);

            .top-wrap {
              color: var(--dark-blue);
            }

            .title,
            .details {
              color: white;
            }
          }

          .top-wrap {
            color: white;
            font-size: 5rem;
            font-weight: 700;
            text-align: right;
            margin-bottom: 2rem;
            padding-right: 1.5rem;
            position: relative;

            @media screen and (max-width: 767px) {
              font-size: 3rem;
              margin-bottom: 0rem;
            }

            @media screen and (max-width: 480px) {
              font-size: 2rem;
            }

            ::before {
              content: "";
              position: absolute;
              left: 0;
              top: -2rem;
              width: 5rem;
              height: 5rem;
              background-color: var(--secondary);

              @media screen and (max-width: 767px) {
                width: 4rem;
                height: 4rem;
              }

              @media screen and (max-width: 480px) {
                width: 3rem;
                height: 3rem;
              }
            }
          }

          .bottom-wrap {
            padding: 2rem;

            @media screen and (max-width: 767px) {
              padding: 1.5rem 1rem 1rem 1rem;
            }

            @media screen and (max-width: 480px) {
              padding: 1rem 1rem 1rem 1rem;
            }

            .title {
              font-size: 1.2rem;
              font-weight: 600;
              text-transform: uppercase;

              @media screen and (max-width: 767px) {
                font-size: 1.1rem;
              }

              @media screen and (max-width: 480px) {
                font-size: .9rem;
                line-height: 1rem;
              }
            }

            .details {
              @media screen and (max-width: 767px) {
                font-size: .9rem;
                line-height: 1rem;
              }
            }
          }
        }
      }

      .active {
        .inner-wrap {
          background-color: var(--primary);

          .top-wrap {
            color: var(--dark-blue);
          }

          .title,
          .details {
            color: white;
          }
        }
      }
    }
  }
`;
