import React, {useState, useEffect} from 'react';
import { FaChevronUp } from "react-icons/fa";
import { BackToTopButtonStyle } from '../globalStyles';



const BackToTopButton = () => {

    const [toTop, setToTop] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            {window.scrollY > 500 ? setToTop(true) : setToTop(false)}
        });
    });

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return (
        <>
            {toTop && (
                <BackToTopButtonStyle 
                    onClick= {scrollUp}
                >
                    <FaChevronUp 
                        style={{
                            fontSize: '1.5rem'
                        }}
                    />
                </BackToTopButtonStyle>
            )}
        </>
    );
}

export default BackToTopButton;