import React from "react";
import { ServicesPageStyles } from "../styles/ServicesPage.style";
import {
  MainContainer,
  PageTitle,
  SectionSubtitle2,
  SectionTitle2,
} from "../globalStyles";
import heroImg from "../assets/hero.jpg";
import Plans from "../components/Plans";
import CallbackSection from "../components/CallbackSection";
import ClientCarausal from "../components/ClientCarausal";
import LuxuryCars from "../components/LuxuryCars";
import { Link } from "react-router-dom";

const ServicesPage = () => {
  return (
    <ServicesPageStyles>
      <PageTitle>
        <h1 className="headline">Services We Offer</h1>
        <p className="path">Home / Services</p>
      </PageTitle>
      <MainContainer>
        <SectionTitle2>Exploring India, one road trip at a time</SectionTitle2>
        <hr />
        <p className="content">
          To us, a road trip is one of the most exhilarating ways to travel the
          length and breadth of India. There's always something to look at,
          something to explore and to experience. Because we love travelling by
          road so much, we've been striving to make sure you have a great
          experience too. We wanted more of you to go on a road trip, and more
          of you to experience the same joys of travel that we do. Instead of
          driving, why not sit back and take our chauffeur driven cabs on your
          next vacation? We believe that the time you spend on your vacation
          should be entirely yours. So now, we are in{" "}
          <Link style={{ textDecoration: "none" }} to="/about-jm-travels">
            2000+ cities across India
          </Link>
          - to help you travel to wherever your heart desires.
          <br />
          <br />
          We love that you're free to stop to breathe in clean air, learn about
          cultures and taste local food when you travel by cabs. We love that
          these wholesome experiences make travelling better and enrich our
          lives. We live for the surprises we find on road trips.
          <br />
          <br />
          No city is too big or too small. We know travellers and wanderers like
          us are everywhere. You live near Khajuraho, you live near Aleppey, and
          you live near Alibag and near Tranquebar. We want you to visit them
          all. Pack your bags every weekend and explore everything there is to
          see around you.
          <br />
          <br />
          To make planning your vacation easier, you can book a cab by calling
          us on{" "}
          <a style={{ textDecoration: "none" }} href="tel:+918178165852">
            +91 817-816-5852
          </a>{" "}
          if you'd like to discuss your itinerary with our executives in detail.
          When you book an outstation cab with us, we'll send you a travel kit
          and help you plan your itinerary. Our expert drivers will guide you
          through some of the best experiences India has to offer. From the time
          you make a booking with us, to the time you get back home, we'll make
          sure you have a great road trip.
          <br />
          <br />
          Trust us when we say: Travel begins with <strong>JM Travels</strong>.
        </p>
        <div className="block-title">
          Here's why India Trusts Savaari car rentals for all its travel needs
        </div>
        <p className="content">
          Over the last decade or so, we have established itself as a leading
          taxi brand in India. With presence in{" "}
          <Link style={{ textDecoration: "none" }} to="/about-jm-travels">
            2000+ cities, servicing over 5 lakh routes
          </Link>{" "}
          and over <strong>a million trips</strong> completed, we are one of the
          most credible and reliable taxi services around. However, this
          reliability does not come at a greater cost, in fact, JM Travels's
          taxi fare is economical and extremely competitive. Moreover, we
          promise you that your bill will be{" "}
          <strong>completely transparent</strong> for our cab service nearby.
          <br />
          <br />
          Our <strong>expert drivers</strong> are all verified and held to the
          utmost professional standards. They undergo a great deal of training
          and sensitization to ensure that our customers' experience is truly
          exemplary. We also provide <strong>doorstep pickup and drops</strong>{" "}
          at no extra charge.
          <br />
          <br />
          We pride ourselves on our <strong>on-time pickup</strong> and because
          we believe in last minute plans, you can even book a taxi nearby and
          have it at your front door within the hour.
          <br />
          <br />
          We have an extensive fleet of cars and our taxi service nearby will
          allow you to choose from multiple car options- from hatchbacks and
          sedans to Tempo travelers and even luxury cars.
        </p>
        <div className="block-title">
          Choose from a wide range of car hire options to suit your needs
        </div>
        <p className="content">
          In addition to our customer centric promises of quality, we are also
          proud of the numerous cab choices that we provide to our customers.
          This creates a unique differentiator for us over the local taxi
          service providers who would at best offer two or three categories of
          cars. Here are some of the car types that we offer round the clock
          services on:
          <br />
          <br />
          <ul>
            <li>
              <span>Hatchbacks</span> - These are great options for budget trips
              over short distances for small families. The models range from the
              ubiquitous Indica to Micra and Ritz.
            </li>
            <li>
              <span>Sedans</span> - Our most popular category - a category that
              will make a trip with 3-4 passengers very comfortable. While other
              local players offer lower end models like Indigo and Verito, we
              take pride in offering the spacious and comfortable Toyota Etios
              to all our customers, with no extra charges.
            </li>
            <li>
              <span>Innovas</span> - The grand-daddy of cabs. If you haven't
              experienced an Innova on a highway with your family accompanying
              you on a long road trip, you are missing out on some serious
              travel gratification. Available in 3 variants, the 7 seater, 8
              seater and the posh new Innova Crysta, this category is a crowd
              favourite.
            </li>
            <li>
              <span>Luxury Cars</span> - For those rare moments of indulgence,
              you can book a BMW, Mercedes Benz, a Corolla or Camry at the most
              affordable rates with us.
            </li>
            <li>
              <span>Tempo Traveller</span> - Deserving of the ‘Made in India'
              tag, the Tempo Traveller has been faithfully ferrying passengers
              on the Indian roads for ages. Be it corporate events, business
              getaways or a large family picnic, a Tempo Traveller will never
              let you down. We have various TT variants available, from the 12
              seater to the 18 seater, through the 16 and 14 seater
              configurations.
            </li>
          </ul>
        </p>
        <div className="block-title main-title">
          Local Car Rental - Hire taxi services nearby across India
        </div>
        <p className="content">
          Ever found yourself in a new city and wanted to explore its hidden
          nooks and crannies? Have you ever felt the urge to take the day off
          and just explore the most interesting parts of your city? Spend an
          entire day exploring the oldest monuments, the hidden alleyways, the
          oldest eateries or the best places to shop.
          <br />
          <br />
          Cab booking has never been this easy. Book a cab with Savaari in under
          a minute and we will{" "}
          <strong>delight you with our quality service</strong>.
        </p>
        <hr />
        <div className="block-title main-title">
          Book Outstation Cabs with driver
        </div>
        <p className="content">
          We love that when you travel by cabs, you are your own boss. You can
          stop anywhere for a quick break, take a detour to a hidden gem on the
          journey and relinquish the local cuisines. The excitement of a road
          trip shoots up with the number of surprises it beholds. You explore
          new places and suddenly no town is small. For the wanderers you love
          being on the road, we have We live for the surprises we find on road
          trips, and the new destinations we can explore. No city is too big or
          too small. No matter which corner of the country you live in, we have
          the <strong>best-in-class</strong> chauffeur driven services for you.
          If you are a wanderer, head to over 15 lakh destinations whenever you
          like. All it takes is to pack your bags and rent a Savaari to set out
          on your next exploration. With Savaari's Outstation cab services, you
          can never go wrong on the road. We ensure exemplary service, expert
          local chauffeurs to guide you through your journey, clean and
          sanitised vehicles for your comfort and completely a transparent
          billing system so that you only pay what you see.
        </p>
        <hr />
        <div className="block-title main-title">
          JM Travels's Corporate Program - A Cab for all your Transport needs
        </div>
        <p className="content">
          We have been at the forefront of organizing the car rental industry
          and has built a strong brand over the last decade. We are now India's
          largest cab service spanning across 2000 cities with over 12 years of
          experience in providing road-transport solutions. We take great pride
          in our exceptional customer service delivery supported by courteous
          driver partners and an efficient technology back-end that is being
          constantly refined. Our corporate cab services come closely on the
          heels of our successes within the retail sector and we have seen
          already some great results here as well.
          <br />
          <br />
          Because of Savaari's PAN India presence, you don't ever need to worry
          about managing a roster of local vendors- we are your one stop shop
          for all you employee transportation cab requirements. There are 70+
          satisfied corporate clients on our roster and they are guaranteed our
          reliable services, with an exemplary 24x7 support.
          <br />
          <br />
          <ul>
            <li>
              <span>Hourly Rentals</span> : Pickup and drop services within city
              limits.
            </li>
            <li>
              <span>Outstation Cabs</span> : Pickup and drop services within
              city limits.
            </li>
            <li>
              <span>Airport Transfers</span> : Pickup and drop services within
              city limits.
            </li>
          </ul>
          <br />
          All 3 services pertain to both spot rentals as well as monthly car
          hires.
          <br />
          <br />
          Our reliable corporate services cover 79 cities, across 29 states.
          Additionally, our fleet of vehicles comprises of hatchbacks, premium
          sedans, Innovas and even Tempo Travellers, making Savaari the most
          convenient and hassle-free choice for employee transportation. Our
          corporate offering includes, but is not limited to:
          <br />
          <br />
          <ul>
            <li>
              <span>MICE</span> : We provide end-to-end transportation for
              meetings, conferences, exhibitions and team-related activities.
              Due to our extensive inventory of drivers across India, our cabs
              for MICE events are a popular offering.
            </li>
            <li>
              <span>Holidays</span> : Whether official team holidays or personal
              vacations, we are the no. 1 outstation and local cab service and
              will handle all your cab requirements.
            </li>
            <li>
              <span>Monthly Car Rental Requirements</span> : We will ensure that
              you get the best corporate cab prices along with excellent quality
              vehicles and experienced drivers for your monthly car rental
              requirements. The services will be provided at bulk discounted
              rates.
            </li>
          </ul>
          <div className="block-title">
            Why choose TM Travels - What we bring to the table
          </div>
          <ul>
            <li>
              <span>Affordable Rates</span> : Savings on business travel through
              special customized fares.
            </li>
            <li>
              <span>Round the clock customer service</span> : 24x7 employee
              helpline and booking support.
            </li>
            <li>
              <span>Transparent Billing</span> : Paperless & GST compliant
              seamless billing process.
            </li>
            <li>
              <span>Largest verified driver network</span> : Well trained and
              experienced driver partners at disposal.
            </li>
            <li>
              <span>PAN India presence</span> : Be it metro cities like
              <strong>Bangalore</strong>, <strong>Mumbai</strong> or{" "}
              <strong>Delhi</strong>, or smaller cities like{" "}
              <strong>Ranchi</strong>,<strong>Ludhiana</strong> or{" "}
              <strong>Rameshwaram</strong>, we are just a call or a click away.
            </li>
          </ul>
        </p>
      </MainContainer>
      <ClientCarausal />
      <CallbackSection />
    </ServicesPageStyles>
  );
};

export default ServicesPage;
