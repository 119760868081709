import styled from "styled-components";

export const PaymentPageStyles = styled.div`
  display: block;
  margin: 4rem 0;

  @media screen and (max-width: 767px) {
    margin: 0;
  }

  .inner-container {
    .row {
      justify-content: center;

      .main-container {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        @media screen and (max-width: 767px) {
          box-shadow: none;
        }
      }
    }

    .left {
      background-color: var(--primary);
    }

    .right {
      padding: 3rem 2rem;

      @media screen and (max-width: 767px) {
        padding: 3rem 1.5rem;
      }

      .header-wrap {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .wpsc-container {
          @media screen and (max-width: 767px) {
            font-size: 0.9rem;
          }
        }

        .title {
          font-size: 1.8rem;

          @media screen and (max-width: 767px) {
            font-size: 1.2rem;
          }
        }
      }

      .form-wrap {
        margin-top: 2rem;

        @media screen and (max-width: 480px) {
          font-size: 0.9rem;
        }

        .input-wrap {
          margin-bottom: 1rem;

          .input-title {
            margin-bottom: 0.1rem;
          }

          .input-field {
            padding: 5px;
            outline: none;
            width: 80%;
            border: 1px solid lightgrey;

            @media screen and (max-width: 480px) {
              width: 100%;
            }

            ::placeholder {
              font-size: 0.9rem;
              font-style: italic;
            }
          }
        }

        .action-wrap {
          margin-top: 2rem;

          .btn-wrap {
            .action-btn {
              padding: 0.4rem 1.5rem;
              outline: none;
              border: none;

              @media screen and (max-width: 480px) {
                padding: .3rem 1.2rem;
              }
            }

            .left {
              margin-right: 2rem;
              color: white;
              background-color: var(--primary);

              :hover {
                color: black;
                background-color: var(--secondary);
              }
            }

            .right {
              background-color: var(--secondary);

              :hover {
                color: white;
                background-color: var(--primary);
              }
            }
          }
        }

        .footer-wrap {
          display: flex;
          justify-content: space-between;
          margin-top: 3rem;

          .ws-link {
            font-size: 0.9rem;
            color: var(--primary);
            text-decoration: none;

            @media screen and (max-width: 480px) {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
`;
