import React from 'react';
import {MainContainer, SectionTitle, SectionSubtitle} from '../globalStyles';
import { ClientCarausalStyle } from '../styles/ClientCarausal.style';
import ktcLogo from '../assets/ktc-logo.webp';
import sawariLogo from '../assets/sawari.webp';
import ecoLogo from '../assets/eco.webp';
import avisLogo from '../assets/avis.webp';
import carzonLogo from '../assets/carzon.webp';
import euroLogo from '../assets/eurocar.webp';

const ClientCarausal = () => {
    return (
        <MainContainer>
            <SectionTitle>
                Our Partners
            </SectionTitle>
            <SectionSubtitle>
                We are happily working with these clients
            </SectionSubtitle>
            <ClientCarausalStyle>
                <div className="inner-container">
                    <div className="card">
                        <img src={ktcLogo} alt="ktc logo" className="client-logo" />
                    </div>
                    <div className="card">
                        <img src={sawariLogo} alt="ktc logo" className="client-logo" />
                    </div>
                    <div className="card">
                        <img src={euroLogo} alt="ktc logo" className="client-logo" />
                    </div>
                    <div className="card">
                        <img src={ecoLogo} alt="ktc logo" className="client-logo" />
                    </div>
                    <div className="card">
                        <img src={carzonLogo} alt="ktc logo" className="client-logo" />
                    </div>
                    <div className="card">
                        <img src={avisLogo} alt="ktc logo" className="client-logo" />
                    </div>
                </div>
            </ClientCarausalStyle>
        </MainContainer>
    );
};

export default ClientCarausal;