import styled from "styled-components";

export const TestimonialsStyle = styled.div`

    .slick-dots li.slick-active button:before {
    color:var(--primary) !important;

    }

  .slider-wrap {
    display: flex;
    justify-content: center;

    .slider-inner-wrap {
      width: 50%;

      @media screen and (max-width: 991px) {
        width: 70%;
      }

      @media screen and (max-width: 480px) {
        width: 80%;
      }
    }

    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      border-radius: 5px;

      :hover {
        cursor: pointer;
      }
    }

    .slick-prev::before,
    .slick-next::before {
      display: none;
    }

    .slick-arrow.slick-prev {
      left: -5rem;

      @media screen and (max-width: 767px) {
        left: -3rem;
      }

      @media screen and (max-width: 480px) {
        left: -2rem;
      }
    }

    .slick-arrow.slick-next {
      right: -5rem;

      @media screen and (max-width: 767px) {
        right: -3rem;
      }

      @media screen and (max-width: 480px) {
        right: -2rem;
      }
    }

    .slick-dots li button:before {
      font-size: 1rem;
    }
  }
`;

export const CardStyle = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  .card-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;

    .image-wrap {
      .card-image {
        border-radius: 50%;
        border: 1px solid lightgrey;
        max-width: 100px;
        height: auto;
        padding: 7px;
      }
    }

    .test {
      margin-top: 1rem;

      .message {
      }
    }

    .name-wrap {
      .name {
        color: var(--primary);

        span {
          color: gray;
          font-style: italic;
          margin-left: 0.3rem;
          font-size: 0.9rem;
          font-weight: 300;
        }
      }
    }
  }
`;
