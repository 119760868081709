import React from 'react';
import AboutSection from '../components/AboutSection';
import CallbackSection from '../components/CallbackSection';
import ClientCarausal from '../components/ClientCarausal';
import ContactForm from '../components/ContactForm';
import Hero from '../components/Hero';
import LuxuryCars from '../components/LuxuryCars';
import Plans from '../components/Plans';
import ServicesHome from '../components/ServicesHome';
import ServicesSection from '../components/ServicesSection';
import Testimonials from '../components/Testimonials';
import { HomePageStyles } from '../styles/Home.style';


const Home = () => {
    return (
        <HomePageStyles>
            <Hero />
            <AboutSection />
            <ServicesSection />
            <LuxuryCars />
            <ServicesHome />
            <Plans />
            <ContactForm />
            <Testimonials />
            <ClientCarausal />
            <CallbackSection />
        </HomePageStyles>
    );
};

export default Home;