import styled from "styled-components";

export const AboutSectionStyle = styled.div`
  .desktop-only {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .row {
    .about-image {
      object-fit: contain;
      width: 100%;
      height: 100%;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .btn-wrap {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
`;
