import React from "react";
import {
  MainContainer,
  SectionSubtitle2,
  SectionTitle2,
} from "../globalStyles";
import { LuxuryCarsStyle } from "../styles/LuxuryCars.style";
import luxCars from "../assets/lux-car.webp";

const LuxuryCars = () => {
  return (
    <MainContainer>
      <LuxuryCarsStyle>
        <div className="row">
          <div className="col-md-6 col-sm-12 left-container">
            <SectionTitle2>Luxury Car Rental</SectionTitle2>
            <SectionSubtitle2>
              We provide all kind of luxury cars across India
            </SectionSubtitle2>
            <p className="content">
              Travel to India offers an ultimate range of luxury and premium car
              on rent which includes many brands like Rolls Royace Ghost
              Series-2, Chrysler Stretched Limousine, Mercedes Benz S Class 500,
              Audi A8 premium car rental, Mercedes Benz E Class 250 premium car
              rental service and many other. Premium car rental India is very
              reasonable for rent and best for business meetings and conferences
              from one city to another. They are highly comfortable and safe for
              long or short journey. Our affordable and market compatible deals
              on premium and luxury cars are just unmatchable.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 right-container">
            <div className="inner-container">
              <img src={luxCars} alt="" className="wps-image" />
            </div>
          </div>
        </div>
      </LuxuryCarsStyle>
    </MainContainer>
  );
};

export default LuxuryCars;
